<script>
	import { onMount, createEventDispatcher, afterUpdate } from 'svelte';
	import Button from './Button.svelte';
	import ColorPicker from './ColorPicker.svelte';

	import ColorPickerSimple from './ColorPickerSimple.svelte';

	export let value = {};
	export let onClear;
	export let clearLabel = 'Clear';

	let shadowX = 0;
	let shadowY = 0;
	let shadowBlur = 0;
	let shadowSpread = 0;
	let shadowColor = 'hsla(0, 0%, 0%, 1)';
	let shouldUpdate = true;

	const dispatch = createEventDispatcher();

	const isSafari = navigator.userAgent.indexOf('Safari') === -1 ? false : true;

	afterUpdate(() => {
		syncValues();
	});

	/**
	 * @summary
	 * Sync props
	 *
	 * @description
	 * For Safari - we should reset shouldUpdate
	 * For other browsers we shouldn't
	 * This is a bug related to how prop updates are triggered
	 */
	const syncValues = () => {
		if (!value || !shouldUpdate) {
			return;
		}
		shadowX = value.shadowX || 0;
		shadowY = value.shadowY || 0;
		shadowBlur = value.shadowBlur || 0;
		shadowSpread = value.shadowSpread || 0;
		shadowColor = value.shadowColor;
		if (isSafari) {
			shouldUpdate = false;
		}
	}

	/**
	 * @summary
	 * Handle change event
	 *
	 * @description
	 * First enable prop sync
	 * Generate a change issue
	 * Then disable prop sync
	 * This is needed as prop update event triggers before this change event
	 *
	 * For Safari - we should reset shouldUpdate
	 * For other browsers we shouldn't
	 * This is a bug related to how prop updates are triggered
	 *
	 * @returns {void}
	 */
	const onChange = () => {
		shouldUpdate = true;
		value = {
			shadowX,
			shadowY,
			shadowBlur,
			shadowSpread,
			shadowColor,
		};
		dispatch('input', value);
		if (!isSafari) {
			shouldUpdate = false;
		}
	}

	/**
	 * @summary
	 * Handle clear event
	 */
	const handleClear = () => {
		shadowX = 0;
		shadowY = 0;
		shadowBlur = 0;
		shadowSpread = 0;
		shadowColor = null;
		if (onClear && typeof onClear === 'function') {
			onClear();
		}
		onChange();
	}
</script>

<div>
	<label for="shadowX">Box Shadow X: {shadowX}px</label>
	<input type="range" min={0} step={1} max={100} name="shadowX" bind:value={shadowX} on:input={onChange} />
</div>
<div>
	<label for="shadowY">Box Shadow Y: {shadowY}px</label>
	<input type="range" min={0} step={1} max={100} name="shadowY" bind:value={shadowY} on:input={onChange} />
</div>
<div>
	<label for="shadowBlur">Box Shadow Blur: {shadowBlur}px</label>
	<input type="range" min={0} step={1} max={100} name="shadowBlur" bind:value={shadowBlur} on:input={onChange} />
</div>
<div>
	<label for="shadowSpread">Box Shadow Spread: {shadowSpread}px</label>
	<input type="range" min={0} step={1} max={100} name="shadowSpread" bind:value={shadowSpread} on:input={onChange} />
</div>
<div>
	<!-- <ColorPickerSimple bind:value={shadowColor} on:input={onChange} label="Box Shadow Color" /> -->
	<ColorPicker bind:value={shadowColor} on:input={onChange} label="Box Shadow Color" />
</div>
<div class="footer">
	<Button type="button" label={clearLabel} onClick={handleClear} />
</div>	
