<script>
	import IconPublish from "./icons/IconPublish.svelte";
	import IconSave from "./icons/IconSave.svelte";
	import Loader from "./Loader.svelte";

	export let onClick;
	export let isBusy;
	export let isUpdate;
</script>

<button on:click={onClick} disabled={isBusy} class="is-save" data-title={isUpdate ? 'Update' : 'Publish'}>
	{#if !isUpdate && !isBusy}
		<IconPublish />
		<span>Publish</span>
	{/if}
	{#if isUpdate && !isBusy}
		<IconSave />
		<span>Update</span>
	{/if}
	{#if isBusy}
		<Loader />
		<span>Working</span>
	{/if}
</button>

<!-- <style global>
	.is-save {
		--animation-duration: 150ms;
		/* --color: hsla(191.9, 49.3%, 67.6%, 0.64); */
		/* --color-hover: hsla(191.9, 49.3%, 67.6%, 1); */
		--color: #38b000;
		--color-hover: #008000;
		z-index: 1000;
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: var(--toolbar-width);
		height: var(--toolbar-width);
		margin: 0;
		padding: 2em 1em;
		background-color: transparent;
		/* background-color: var(--color); */
		/* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); */
		/* border-radius: 99em; */
		border: none;
		color: var(--color);
		cursor: pointer;
		/* color: #fff; */
		/* font-size: 0.6em; */
		transition: all var(--animation-duration);
	}
	:global(button.is-save) {
		border: none;
	}
	:global(button.is-save:disabled) {
		cursor: not-allowed;
	}
	.is-save :global(svg) {
		width: 1.5rem;
		height: 1.5rem;
	}
	.is-save :global(i) {
		border-color: var(--color-hover);
	}
	.is-save :global(path),
	.is-save :global(line),
	.is-save :global(circle),
	.is-save :global(polygon),
	.is-save :global(polyline) {
		/* fill: var(--color); */
		fill: transparent;
		stroke: var(--color);
		stroke-width: 2;
		transition: all var(--animation-duration);
	}
	.is-save:hover {
		color: var(--color-hover);
	}
	.is-save:hover :global(path),
	.is-save:hover :global(line),
	.is-save:hover :global(circle),
	.is-save:hover :global(polygon),
	.is-save:hover :global(polyline) {
		/* fill: var(--color-hover); */
		stroke: var(--color-hover);
		/* background-color: var(--color-hover); */
	}
</style> -->