<script>
	import axios from 'axios';
	import { onMount } from 'svelte';
	import { v4 } from 'uuid';
	import localStorage from 'localforage';
	import Editor from './Editor.svelte';
	import Footer from './Footer.svelte';
	import SaveButton from './SaveButton.svelte';
	import Sidebar from './sidebar/Sidebar.svelte';

	import { getNotificationsContext } from 'svelte-notifications';
	import PublishForm from './publish-form/PublishForm.svelte';
	import Menu from './menu/Menu.svelte';
	const { addNotification } = getNotificationsContext();

	// sidebar status
	let isSidebarOpen = false;
	let isSidebarLocked = false;
	let isAutosaveDisabled = false;
	let activeSidebarId = null;
	let subdomain = '';
	let initialSubdomain = '';
	let liveUrl = '';
	let username = '';
	let otp = '';
	let title = '';
	let description = '';
	let isUpdate = false;

	// editor variables
	let isBusy = false;
	let isLoading = true;
	let editor = null;
	let editorData = null;
	let activeBlockIndex = 1;
	let activeBlock = 'ele';

	// module variables
	let logo = {};
	let bodyBg = { type: 'transparent' };
	let headerBg = { type: 'transparent' };
	let articleStyles = {};
	let images = [];
	let logoImages = [];
	let smartButtons = [];
	let stripeButtons = [];

	// layout width and max width
	let layoutWidth = 50;
	const layoutWidthMin = 50;
	const layoutWidthMax = 80;

	// active stripe button being edited
	let activeStripeId = '';
	let activeStripePrivateKey = '';
	let activeStripePriceId = '';
	let activeStripeIsIdealEnabled = '';

	// active image being edited
	let activeimageId = '';
	let activeimageTitle = '';
	let activeimageAlt = '';

	/**
	 * @summary
	 * Set the active editor
	 */
	const setEditor = (newEditor) => {
		editor = newEditor;
	};

	/**
	 * @summary
	 * Set the active editor
	 */
	const setActiveBlockIndex = (newIndex) => {
		activeBlockIndex = newIndex;
	};

	/**
	 * @summary
	 * Set the active editor block
	 */
	const setActiveBlock = (newBlock) => {
		activeBlock = newBlock;
	};

	/**
	 * @summary
	 * Enable auto save
	 */
	const onEnableAutosave = () => {
		isAutosaveDisabled = false;
	};

	/**
	 * @summary
	 * Set the active editor block
	 */
	const onDisableAutosave = () => {
		isAutosaveDisabled = true;
	};

	/**
	 * @summary
	 * Delete all drafts, and redirect to new screen
	 */
	const onNew = async () => {
		await localStorage.clear();
		window.location = window.location.origin;
	};

	/**
	 * @summary
	 * Get subdomain provided via query args in url
	 *
	 * @returns {string|undefined}
	 */
	const getSubdomain = () => new URLSearchParams(location.search).get('subdomain') || '';

	/**
	 * @summary
	 * Get draft key
	 * @returns {Mixed} object | undefined
	 */
	const getDraftKey = () => {
		subdomain = getSubdomain();
		if (subdomain) {
			return subdomain;
		}
		return location.host;
	};

	/**
	 * @summary
	 * Get draft from local storage
	 * @returns {Mixed} object | undefined
	 */
	const getDraft = async () => {
		const key = getDraftKey();
		console.log('getDraft - key', key);
		try {
			return await localStorage.getItem(key);
		} catch (error) {
			console.log('getDraft - error fetching draft - ', error);
		}
	};

	/**
	 * @summary
	 * Get draft from local storage
	 * @returns {Mixed} object | undefined
	 */
	const saveDraft = () => {
		if (isAutosaveDisabled) {
			return;
		}
		localStorage.setItem(getDraftKey(), getPublishPayload());
	};

	/**
	 * @summary
	 * Load the current subdomain
	 *
	 * @returns {string|undefined}
	 */
	const loadSubdomain = async () => {
		let response;
		let data;

		if (subdomain) {
			try {
				response = await axios.post('/api/subdomain', { subdomain });
				data = response.data;
			} catch (err) {
				console.log('Error loading subdomain', err);
				// return;
			}
		}

		const draft = await getDraft();
		if (draft) {
			if (!data || draft.timestamp >= data.timestamp) {
				data = draft;
			}
		}

		console.log('data - ', data);

		if (!data) {
			return;
		}

		editorData = data.data;
		title = data.title || '';
		description = data.description || '';
		bodyBg = data.bodyBg || {};
		headerBg = data.headerBg || {};
		articleStyles = data.articleStyles || {};
		logo = data.logo || {};
		images = data.images || [];
		logoImages = data.logoImages || [];
		smartButtons = data.smartButtons || [];
		stripeButtons = data.stripeButtons || [];
		layoutWidth = data.layoutWidth || 50;

		// updates to be run after setting variables
		onLayoutWidthChange();
	};

	const addLogoClickHandler = () => {
		const eleLogo = document.getElementById('logo');
		if (!eleLogo) {
			return;
		}
		eleLogo.addEventListener('click', (e) => {
			e.preventDefault();
			openSidebarPanel('logo');
		});
	};

	onMount(async () => {
		isLoading = true;
		addLogoClickHandler();
		subdomain = getSubdomain();
		initialSubdomain = subdomain || '';
		await loadSubdomain();
		if (subdomain) {
			isUpdate = true;
		}
		isLoading = false;
	});

	/**
	 * @summary
	 * Do cleanup whenever sidebar toggles
	 * 
	 * @description
	 * Toggle css class from body to help styling elements
	 * Reset active stripe button values
	 * Reset active image values
	*/
	$: dataChanged(isSidebarOpen);
	function dataChanged(value) {
		if (value) {
			document.body.classList.add('has-sidebar');
		} else {
			document.body.classList.remove('has-sidebar');
			activeStripeId = '';
			activeStripePrivateKey = '';
			activeStripePriceId = '';
			activeStripeIsIdealEnabled = '';
			activeimageId = '';
			activeimageTitle = '';
			activeimageAlt = '';
		}
	}

	/**
	 * @summary
	 * Handle logo change event
	 */
	const onLogoChange = (newLogo) => {
		logo = newLogo;
		saveDraft();
	};

	/**
	 * @summary
	 * Handle logo change event
	 */
	const onLogoInsert = (newLogo) => {
		logoImages.push(newLogo);
		saveDraft();
	};

	/**
	 * @summary
	 * Handle body bg change event
	 */
	const onBodyBgChange = (newBodyBg) => {
		bodyBg = newBodyBg;
		saveDraft();
	};

	/**
	 * @summary
	 * Handle header bg change event
	 */
	const onHeaderBgChange = (newHeaderBg) => {
		headerBg = newHeaderBg;
		saveDraft();
	};

	/**
	 * @summary
	 * Handle article style change event
	 */
	const onArticleStyleChange = (newArticleStyles) => {
		articleStyles = newArticleStyles;
		saveDraft();
	};

	/**
	 * @summary
	 * Handle live preview of images
	 *
	 * @description
	 * If an existing live preivew image is found then update it else
	 * add a new image
	 *
	 * @returns {void}
	 */
	const onImageLivePreview = async (newImage) => {
		const { id, base64, title, alt } = newImage;
		if (!id) {
			return;
		}
		const { width, height } = await getImageDimensions(base64);
		const eleParagraph = document.getElementById(id);
		if (eleParagraph) {
			replaceImage(eleParagraph, base64, alt, title, width, height);
			return;
		}
		addImage(id, base64, alt, title, width, height);
	};

	/**
	 * @summary
	 * Replace existing image element
	 *
	 * @param eleTarget Target DOM element
	 * @param src image src attribute - can be either base64 string or url
	 * @param alt image alt value
	 * @param title image title value
	 * @param width image width value
	 * @param height image height value
	 */
	const replaceImage = (eleTarget, src, alt, title, width, height) => {
		eleTarget.innerHTML = '';
		// eleTarget.style.setProperty('--aspect-ratio', `${width} / ${height}`);
		const eleImg = document.createElement('img');
		eleImg.setAttribute('src', src);
		eleImg.setAttribute('title', title);
		eleImg.setAttribute('alt', alt);
		eleTarget.appendChild(eleImg);
	};

	/**
	 * @summary
	 * Add new image element
	 *
	 * @param eleTarget Target DOM element
	 * @param src image src attribute - can be either base64 string or url
	 * @param alt image alt value
	 * @param title image title value
	 * @param width image width value
	 * @param height image height value
	 */
	const addImage = (id, src, alt, title, width, height) => {
		const nextBlockId = v4();
		const html = `
			<p id="${id}">
				<img src="${src}" title="${encodeURI(title)}" alt="${encodeURI(alt)}" />
			</p>
			<p id="${nextBlockId}">
				<br />
			</p>
		`;
		insertBlockInDom(nextBlockId, html);
	};

	/**
	 * @summary
	 * Get image dimension from source data
	 *
	 * @param src {string} either url or base64 src of image
	 * @returns {Promise} object with width and height on success
	 */
	const getImageDimensions = async (src) =>
		new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				resolve({ width: img.width, height: img.height });
			};
			img.onabort = (err) => {
				console.log('getImageDimensions error', err);
				reject(err);
			};
			img.src = src;
		});

	/**
	 * @summary
	 * Handle header bg change event
	 */
	const onImageAdd = async (newImage) => {
		const { id, alt, title } = newImage;
		const { url, width, height } = newImage.upload;

		// enable auto save
		onEnableAutosave();

		// add image to uploads array
		images.push(newImage);

		// we already have the dimesnions - loading it here just to make sure
		// that the image gets loaded in cache before showing in DOM
		// this resolved image flash issue
		const dimensions = await getImageDimensions(url);

		// find existing paragraph
		let eleParagraph = document.getElementById(id);

		// in case existing paragraph is found then empty it and replace image
		if (eleParagraph) {
			replaceImage(eleParagraph, url, alt, title, width, height);
			saveDraft();
			return;
		}

		addImage(id, url, alt, title, width, height);
		saveDraft();
	};

	/**
	 * @summary
	 *
	 * @param id {string} the id of the image in the images array that we want to edit
	 * Handle stripe button edit event
	 */
	const onImageEdit = (id) => {
		const img = images.find((img) => img.id === id);
		if (!img) {
			return
		}
		activeimageId = id;
		activeimageAlt = img.alt;
		activeimageTitle = img.title;
		onSidebarLock();
		openSidebarPanel('image');
	};

	/**
	 * @summary
	 * Update image attributes - alt and title tag
	 *
	 * @param id {string} the id attribute of the parent element containing the image
	 * @param alt {string} the alt attribute of the image
	 * @param title {string} the title attribute of the image
	 */
	const updateImageAttributes = (id, alt, title) => {
		const eleImgParent = document.getElementById(id);
		if (eleImgParent) {
			const eleImg = eleImgParent.querySelector('img');
			if (eleImg) {
				eleImg.setAttribute('alt', alt);
				eleImg.setAttribute('title', title);
			}
		}
	}

	/**
	 * @summary
	 * An already uploaded image was updated - only title or alt tag - nothing else
	 *
	 * @param newImage {Object} an object with attributes - id / title / alt
	 * @returns {void}
	 */
	const onImageAttributeUpdate = (newImage) => {
		const { id, alt, title } = newImage;
		const img = images.find((img) => img.id === id);
		if (!img) {
			return
		}
		img.alt = alt;
		img.title = title;
		updateImageAttributes(id, alt, title);
		saveDraft();
	}

	/**
	 * @summary
	 * Insert a new HTML block in DOM and update active block id
	 *
	 * @param id {string} next block id
	 * @param html {string} raw html string
	 */
	const insertBlockInDom = (id, html) => {
		// MediumEditor.selection.moveCursor(document, activeBlock, 0);
		// MediumEditor.util.insertHTMLCommand(document, html);
		// const nextBlock = document.getElementById(id);
		// MediumEditor.selection.moveCursor(document, nextBlock, 0);
		// activeBlock = nextBlock;
		tinymce.activeEditor.execCommand('mceInsertContent', false, html);
		document.activeElement.blur();
	};

	/**
	 * @summary
	 * Handle header bg change event
	 */
	const onSmartButtonAdd = (newSmartButton) => {
		const { amount, currency } = newSmartButton;
		// editor.blocks.insert(
		// 	'paragraph',
		// 	{
		// 		text: `
		// 		<button type="button" class="paypal" title="Checkout">
		// 			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px" class="svelte-113qyzv"><path fill="#1565C0" d="M18.7,13.767l0.005,0.002C18.809,13.326,19.187,13,19.66,13h13.472c0.017,0,0.034-0.007,0.051-0.006C32.896,8.215,28.887,6,25.35,6H11.878c-0.474,0-0.852,0.335-0.955,0.777l-0.005-0.002L5.029,33.813l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,0.991,1,0.991h8.071L18.7,13.767z"></path><path fill="#039BE5" d="M33.183,12.994c0.053,0.876-0.005,1.829-0.229,2.882c-1.281,5.995-5.912,9.115-11.635,9.115c0,0-3.47,0-4.313,0c-0.521,0-0.767,0.306-0.88,0.54l-1.74,8.049l-0.305,1.429h-0.006l-1.263,5.796l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,1,1,1h7.333l0.013-0.01c0.472-0.007,0.847-0.344,0.945-0.788l0.018-0.015l1.812-8.416c0,0,0.126-0.803,0.97-0.803s4.178,0,4.178,0c5.723,0,10.401-3.106,11.683-9.102C42.18,16.106,37.358,13.019,33.183,12.994z"></path><path fill="#283593" d="M19.66,13c-0.474,0-0.852,0.326-0.955,0.769L18.7,13.767l-2.575,11.765c0.113-0.234,0.359-0.54,0.88-0.54c0.844,0,4.235,0,4.235,0c5.723,0,10.432-3.12,11.713-9.115c0.225-1.053,0.282-2.006,0.229-2.882C33.166,12.993,33.148,13,33.132,13H19.66z"></path></svg>
		// 		</button>`,
		// 	},
		// 	{},
		// 	activeBlockIndex
		// );
		// newSmartButton.id = editor.blocks.getBlockByIndex(activeBlockIndex).id;
		newSmartButton.id = v4();
		const nextBlockId = v4();
		const html = `
			<p id="${newSmartButton.id}" align="center">
				<img src="/public/paypal.png" style="width: 10em;" />
			</p>
		`;
		// MediumEditor.selection.moveCursor(document, activeBlock, 0);
		// MediumEditor.util.insertHTMLCommand(document, html);
		// smartButtons.push(newSmartButton);

		// // update current block - make sure next block gets focus correctly
		// const nextBlock = document.getElementById(nextBlockId);
		// MediumEditor.selection.moveCursor(document, nextBlock, 0);
		// activeBlock = nextBlock;
		insertBlockInDom(nextBlockId, html);
		saveDraft();
	};

	/**
	 * @summary
	 * Handle header bg change event
	 */
	const onStripeButtonAdd = (button) => {
		button.id = v4();
		const nextBlockId = v4();
		const html = `
			<p id="${button.id}" align="center">
				<img src="/public/stripe.svg" style="width: 10em;" data-stripe  />
			</p>
			<p id="${nextBlockId}">
				<br />
			</p>
		`;
		// MediumEditor.selection.moveCursor(document, activeBlock, 0);
		// MediumEditor.util.insertHTMLCommand(document, html);
		// stripeButtons.push(button);

		// // update current block - make sure next block gets focus correctly
		// const nextBlock = document.getElementById(nextBlockId);
		// MediumEditor.selection.moveCursor(document, nextBlock, 0);
		// activeBlock = nextBlock;
		stripeButtons.push(button);
		insertBlockInDom(nextBlockId, html);
		saveDraft();
	};

	/**
	 * @summary
	 * Handle stripe button edit event
	 */
	const onStripeButtonEdit = (id) => {
		const button = stripeButtons.find((btn) => btn.id === id);
		if (!button) {
			return
		}
		activeStripePrivateKey = button.privateKey;
		activeStripePriceId = button.priceId;
		activeStripeIsIdealEnabled = button.isIdealEnabled;
		activeStripeId = button.id;
		openSidebarPanel('stripe');
	};

	/**
	 * @summary
	 * Handle stripe button update event
	 */
	const onStripeButtonUpdate = (button) => {
		const index = stripeButtons.findIndex((btn) => btn.id === button.id);
		if (index === -1) {
			return
		}
		stripeButtons[index] = button;
		activeStripeId = '';
		activeStripePrivateKey = '';
		activeStripePriceId = '';
		activeStripeIsIdealEnabled = '';
		isSidebarOpen = false;
		saveDraft();
	};

	/**
	 * @summary
	 * Set layuot width
	 */
	const onLayoutWidthChange = () => {
		const ele = document.getElementById('main');
		if (!ele) {
			return;
		}
		ele.style.maxWidth = '100%';
		ele.style.width = `clamp(${layoutWidth}ch, ${layoutWidthMin}ch, ${layoutWidthMax}ch)`;
	};

	/**
	 * @summary
	 * Increase layuot width
	 */
	const onLayoutWidthIncrease = () => {
		layoutWidth = Math.min(layoutWidth + 1, layoutWidthMax);
		onLayoutWidthChange();
		saveDraft();
	};

	/**
	 * @summary
	 * Decrease layuot width
	 */
	const onLayoutWidthDecrease = () => {
		layoutWidth = Math.max(layoutWidth - 1, layoutWidthMin);
		onLayoutWidthChange();
		saveDraft();
	};

	/**
	 * @summary
	 * Update draft whenever content changes
	 */
	const onContentUpdate = () => saveDraft();

	/**
	 * @summary
	 * Lock sidebar
	 */
	const onSidebarLock = () => (isSidebarLocked = true);

	/**
	 * @summary
	 * Unlock sidebar
	 */
	const onSidebarUnlock = () => (isSidebarLocked = false);

	/**
	 * @summary
	 * Toggle sidebar
	 */
	const onSidebarToogle = () => {
		if (isSidebarLocked) {
			return;
		}
		isSidebarOpen = !isSidebarOpen;
	};

	/**
	 * @summary
	 * Open the specified sidebar panel
	 */
	const openSidebarPanel = (id) => {
		activeSidebarId = id;
		isSidebarOpen = true;
	};

	/**
	 * @summary
	 * Get payload object
	 */
	const getPublishPayload = () => ({
		subdomain,
		data: editor ? tinymce.activeEditor.getContent() : editorData,
		title,
		description,
		images,
		smartButtons,
		stripeButtons,
		logo,
		logoImages,
		bodyBg,
		headerBg,
		articleStyles,
		isUpdate,
		timestamp: +new Date(),
		layoutWidth,
		subdomain,
		username,
		otp,
	});

	/**
	 * @summary
	 * Update path in history, close sidebar, mark next actio as upddate and
	 * release busy status
	 *
	 * @param subdomain {string} the subdomain name
	 */
	const updateHistoryAndComplete = (subdomain) => {
		const newurl =
			window.location.protocol +
			'//' +
			window.location.host +
			window.location.pathname +
			`?subdomain=${subdomain}`;
		window.history.pushState({ path: newurl }, '', newurl);
		isUpdate = true;
		initialSubdomain = subdomain;
		isSidebarOpen = false;
		isBusy = false;
	};

	/**
	 * @summary
	 * Open publish form
	 */
	const onPublishFormOpen = () => {
		document.body.classList.add('is-publish-mode');
	};

	/**
	 * @summary
	 * Handle publish event
	 *
	 * @returns {void}
	 */
	const onPublish = async () => {
		const payload = getPublishPayload();
		const response = await axios.post('/api/publish', payload);
		return response;
	};

	const onPublishSuccess = () => {
		// document.body.classList.remove('is-publish-mode');
	}

	const onPublishComplete = () => {
		document.body.classList.remove('is-publish-mode');
		updateHistoryAndComplete(subdomain);
		isUpdate = true;
		initialSubdomain = subdomain;
	}

	const onPublishError = () => {
		// document.body.classList.remove('is-publish-mode');
	}

	const onPublishCancel = () => {
		document.body.classList.remove('is-publish-mode');
	}
</script>

{#if !isLoading}
	<Editor
		{setEditor}
		{setActiveBlockIndex}
		{editorData}
		{images}
		{smartButtons}
		{stripeButtons}
		{setActiveBlock}
		{onContentUpdate}
		{onStripeButtonEdit}
		{onImageEdit}
		bind:activeBlock
	/>
	<Sidebar
		bind:subdomain
		bind:title
		bind:description
		{isBusy}
		{onPublish}
		{isUpdate}
		{isSidebarOpen}
		{isSidebarLocked}
		{onSidebarToogle}
		{onSidebarLock}
		{onSidebarUnlock}
		{activeSidebarId}
		{activeStripeId}
		{activeStripePrivateKey}
		{activeStripePriceId}
		{activeStripeIsIdealEnabled}
		{logo}
		{onLogoChange}
		{onLogoInsert}
		{bodyBg}
		{onBodyBgChange}
		{headerBg}
		{onHeaderBgChange}
		{articleStyles}
		{onArticleStyleChange}
		{images}
		{activeimageId}
		{activeimageTitle}
		{activeimageAlt}
		{onImageAdd}
		{onImageLivePreview}
		{smartButtons}
		{onSmartButtonAdd}
		{onStripeButtonAdd}
		{onStripeButtonUpdate}
		{onDisableAutosave}
		{onEnableAutosave}
		{onImageAttributeUpdate}
	/>
{/if}

<PublishForm 
	onSubmit={onPublish}
	onCancel={onPublishCancel}
	onSuccess={onPublishSuccess}
	onComplete={onPublishComplete}
	onError={onPublishError}
	bind:subdomain
	bind:isUpdate
	bind:initialSubdomain
	bind:liveUrl
	bind:username
	bind:otp
	bind:title
	bind:description
/>

<Footer
	{isBusy}
	{isUpdate}
	{onPublish}
	{onPublishFormOpen}
	{onNew}
	{openSidebarPanel}
	{activeBlock}
	{isSidebarLocked}
	{onSidebarLock}
	{onSidebarUnlock}
	{onEnableAutosave}
	{onDisableAutosave}
	{layoutWidth}
	{layoutWidthMin}
	{layoutWidthMax}
	{onLayoutWidthIncrease}
	{onLayoutWidthDecrease}
/>

<!-- <Menu {subdomain} /> -->

<style>
</style>
