<script>
    import { onMount } from 'svelte';
    import Button from '../Button.svelte';
    import ColorPicker from '../ColorPicker.svelte';
    import SidebarTitle from './SidebarTitle.svelte';
	export let onSidebarToogle;
	export let articleStyles;
	export let onArticleStyleChange;
	let type = 'transparent';
	let background = 'transparent';
	let foreground = '#333';
    let form = null;

    /**
     * Update state on prop change
     * First time only
    */
    onMount(() => {
        // if no prop was passed or alredy loaded then skip
        if (!articleStyles) {
            return;
        }

        // update type
        if (articleStyles.type && articleStyles.type !== type) {
            type = articleStyles.type;
        }

        // update background
        if (articleStyles.background && articleStyles.background !== background) {
            background = articleStyles.background;
        }

        // update foreground
        if (articleStyles.foreground && articleStyles.foreground !== foreground) {
            foreground = articleStyles.foreground;
        }

        // update styles
        updateColors(); 
    });

    /**
     * Apply current styles to article
     */
    const updateColors = () => {
        document.body.style.setProperty('--article-foreground', foreground);
        document.body.style.setProperty('--article-background', 'transparent');
        if (type === 'code') {
            document.body.style.setProperty('--article-background', background);
        }
    }

    /**
     * Update article colors when selection changes
     */
    const onCodeChange = () => {
        updateColors();
        onArticleStyleChange({ type, background, foreground });
    }

    /**
     * Save current styles to global var and close sidebar
     */
	const onSubmit = async () => {
		onSidebarToogle();
	};
</script>

<form on:submit|preventDefault={onSubmit} bind:this={form}>
    <SidebarTitle>Article background</SidebarTitle>
    <div>
        <ColorPicker bind:value={foreground} on:input={onCodeChange} label="Foreground color" />
    </div>
    <div>
        <label for="type">Background Type</label>
        <select name="type" bind:value={type} on:blur={onCodeChange}>
            <option value="transparent">Transparent</option>
            <option value="code">Color Code</option>
        </select>
    </div>
    {#if type === 'code'}
        <div>
            <ColorPicker bind:value={background} on:input={onCodeChange} label="Background color" />
        </div>
    {/if}
    <div>
        <Button label="Save" />
    </div>
</form>
