<script>
	import axios from 'axios';
	import { get } from 'lodash';
	import { Form, Step } from 'svelte-multistep-form';
	import PublishFormBack from './PublishFormBack.svelte';
	import PublishFormCancel from './PublishFormCancel.svelte';
	import PublishFormSubmit from './PublishFormSubmit.svelte';
	import PublishSucccess from './PublishSucccess.svelte';

	export let onSubmit;
	export let onError;
	export let onSuccess;
	export let onCancel;
	export let onComplete;
	export let isUpdate = false;
	export let subdomain = '';
	export let initialSubdomain = '';
	export let username = '';
	export let otp = '';
	export let title = '';
	export let description = '';
	export let liveUrl = '';

	let isComplete = false;
	let activeStep = 1;
	let formComponentRef;
	let isBusy = false;
	let error;

	const genericErrorMessage = 'Something went wrong';

	let multiStepOptions = {
		formTitle: 'Deploy',
		formSubtitle: 'Publish your site in 3 easy steps',
		stepsDescription: [
			// { title: 'Enter title', subtitle: 'Enter site title' },
			// { title: 'Enter description', subtitle: 'Enter meta description' },
			{ title: 'Enter subdomain', subtitle: 'Enter domain name' },
			{ title: 'Enter username', subtitle: 'Enter email or phone number' },
			{ title: 'Enter OTP & publish', subtitle: 'Confirm OTP and publish' },
		],
	};

	const onBack = () => {
		formComponentRef.previousStep();
		activeStep--;
	};

	const onTitleCheck = () => {
		error = undefined;
		if (!title || title.trim().length === 0) {
			error = new Error('Please enter site title');
			return;
		}
		formComponentRef.nextStep();
	};

	const onDescriptionCheck = () => {
		error = undefined;
		if (!description || description.trim().length === 0) {
			error = new Error('Please enter meta description');
			return;
		}
		formComponentRef.nextStep();
	};

	const onDomainCheck = async () => {
		isBusy = true;
		error = undefined;
		try {
			await axios.post('/api/check-domain', { subdomain, isUpdate, initialSubdomain });
			formComponentRef.nextStep();
			activeStep++;
		} catch (err) {
			console.log('onDomainCheck - error - ', err);
			error = new Error(get(err, 'response.data.message', genericErrorMessage));
		} finally {
			isBusy = false;
		}
	};

	const onOwnerCheck = async () => {
		isBusy = true;
		error = undefined;
		try {
			await axios.post('/api/check-owner', { subdomain, username, isUpdate });
			await axios.post('/api/otp-deliver', { subdomain, username, isUpdate });
			formComponentRef.nextStep();
			activeStep++;
		} catch (err) {
			error = new Error(get(err, 'response.data.message', genericErrorMessage));
		} finally {
			isBusy = false;
		}
	};

	/**
	 * @summary
	 * Validate title / description / otp and publish site
	 *
	 * @description
	 * When publish is complete -
	 * set isComplete = true
	 * set liveUrl = url of published site return from backend
	 */
	const onOtpCheck = async () => {
		// check title
		if (!title || title.trim().length === 0) {
			error = new Error('Please enter site title');
			return;
		}

		// check description
		if (!description || description.trim().length === 0) {
			error = new Error('Please enter meta description');
			return;
		}

		// check otp
		if (!otp || otp.trim().length === 0) {
			error = new Error('Please enter OTP');
			return;
		}

		isBusy = true;
		error = undefined;
		try {
			const response = await onSubmit();
			// const response = await axios.post('/api/publish', { subdomain, username, otp, isUpdate });
			liveUrl = get(response, 'data.url');
			isComplete = true;
			onSuccess && onSuccess(response);
		} catch (err) {
			console.log('onOtpCheck - error - ', err);
			error = new Error(get(err, 'response.data.message', genericErrorMessage));
			onError && onError(err);
		} finally {
			isBusy = false;
		}
	};

	/**
	 * @summary
	 * Do cleanup after user clicks continue button on final publish info popup
	 *
	 * @description
	 * Go back twice - i.e. to the first step
	 * Using timeout as we want to reset the form after the animation ends
	 */
	const onPublishComplete = () => {
		onComplete && onComplete();
		setTimeout(() => {
			isComplete = false;
			liveUrl = '';
			otp = '';
			onBack();
			onBack();
		}, 500);
	};

	/**
	 * @summary
	 * Handle publish cancel event - when user clicks on the close button
	 */
	const onPublishCancel = () => {
		onCancel && onCancel();
	};
</script>

<Form {multiStepOptions} bind:this={formComponentRef} class="is-publish-form">
	{#if activeStep > 1}
		<PublishFormBack onClick={onBack} />
	{/if}

	<PublishFormCancel onClick={onPublishCancel} />

	{#if isComplete}
		<PublishSucccess onSubmit={onPublishComplete} url={liveUrl} />
	{/if}

	<!-- <Step>
		<div class="multistep-form-group">
			<input class="input" bind:value={title} placeholder="Enter site title" disabled={isBusy} />
			<PublishFormSubmit {isBusy} onSubmit={onTitleCheck} />
		</div>
		<div class="multistep-form-help">

		</div>
	</Step>
	<Step>
		<div class="multistep-form-group">
			<input class="input" bind:value={description} placeholder="Enter meta description" disabled={isBusy} />
			<PublishFormSubmit {isBusy} onSubmit={onDomainCheck} />
		</div>
		<div class="multistep-form-help">

		</div>
	</Step> -->
	<Step>
		<div class="multistep-form-group">
			<input class="input" name="subdomain" bind:value={subdomain} placeholder="Enter subdomain" required disabled={isBusy} />
			<PublishFormSubmit {isBusy} onSubmit={onDomainCheck} />
		</div>
		<div class="multistep-form-help">
			<!--  -->
		</div>
	</Step>
	<Step>
		<div class="multistep-form-step-value">
			Subdomain <strong>{subdomain}</strong> is available
		</div>
		<div class="multistep-form-group">
			<input class="input" name="username" bind:value={username} placeholder="Enter phone or email" required disabled={isBusy} />
			<PublishFormSubmit {isBusy} onSubmit={onOwnerCheck} />
		</div>
		<div class="multistep-form-help">
			<!--  -->
		</div>
	</Step>
	<Step>
		<div class="multistep-form-step-value">
			Subdomain <strong>{subdomain}</strong> is available
		</div>
		<div class="multistep-form-step-value">
			OTP sent to <strong>{username}</strong>
		</div>
		<div class="multistep-form-group">
			<input class="input" name="title" bind:value={title} placeholder="Enter site title" disabled={isBusy} />
		</div>
		<div class="multistep-form-group">
			<input class="input" name="description" bind:value={description} placeholder="Enter meta description" disabled={isBusy} />
		</div>
		<div class="multistep-form-group">
			<input class="input" bind:value={otp} placeholder="Enter OTP" required disabled={isBusy} />
			<PublishFormSubmit {isBusy} onSubmit={onOtpCheck} style="flex: 1;" />
		</div>
	</Step>
	{#if error}
		<div class="multistep-form-error">
			{error.message}
		</div>
	{/if}
</Form>
