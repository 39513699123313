<script>
	import IconLogo from './icons/IconLogo.svelte';
	import IconImage from './icons/IconImage.svelte';
	import IconPayPal from './icons/IconPayPal.svelte';
	import IconBodyBg from './icons/IconBodyBg.svelte';
	import IconHeaderBg from './icons/IconHeaderBg.svelte';
	import IconStripe from './icons/IconStripe.svelte';
	import IconArticleBg from './icons/IconArticleBg.svelte';
	import SaveButton from './SaveButton.svelte';
	import IconNew from './icons/IconNew.svelte';
	import NewPage from './NewPage.svelte';
	import IconFontSizePlus from './icons/IconFontSizePlus.svelte';
	import IconFontSizeMinus from './icons/IconFontSizeMinus.svelte';
	export let openSidebarPanel;
	export let activeBlock;
	export let isSidebarLocked;
	export let onSidebarLock;
	export let onSidebarUnlock;
	export let onEnableAutosave;
	export let onDisableAutosave;
	export let onPublish;
	export let onPublishFormOpen;
	export let onNew;

	// layout
	export let layoutWidth;
	export let layoutWidthMin;
	export let layoutWidthMax;
	export let onLayoutWidthIncrease;
	export let onLayoutWidthDecrease;

	export let isBusy;
	export let isUpdate;

	const onLogoOpen = () => {
		onSidebarUnlock();
		onEnableAutosave();
		openSidebarPanel('logo');
	};

	const onStripeOpen = () => {
		onSidebarUnlock();
		onEnableAutosave();
		openSidebarPanel('stripe');
	};

	const onPaypalOpen = () => {
		onSidebarUnlock();
		onEnableAutosave();
		openSidebarPanel('paypal');
	};

	const onBodyOpen = () => {
		onSidebarUnlock();
		onEnableAutosave();
		openSidebarPanel('body');
	};

	const onImageSidebarOpen = () => {
		openSidebarPanel('image');
		document.activeElement.blur();
	};
</script>

<footer class="app-footer" id="app-footer">
	<section class="app-editor-toolbar">
		<div id="toolbar">
			<div id="tiny-toolbar" />
		</div>
	</section>
	<section class="app-main-toolbar" id="app-toolbar">
		<NewPage {onNew} />
		<button
			on:click={onLayoutWidthIncrease}
			data-title="Logo"
			data-tooltip="Increase layout width"
			disabled={layoutWidth >= layoutWidthMax}
		>
			<IconFontSizePlus />
			<span>Increase layout width</span>
		</button>
		<button
			on:click={onLayoutWidthDecrease}
			data-title="Logo"
			data-tooltip="Decrease layout width"
			disabled={layoutWidth <= layoutWidthMin}
		>
			<IconFontSizeMinus />
			<span>Decrease layout width</span>
		</button>
		<button on:click={onLogoOpen} data-title="Logo" data-tooltip="Decrease layout width">
			<IconLogo />
			<span>Upload Logo</span>
		</button>
		<button
			on:click={openSidebarPanel('header')}
			data-title="Header"
			data-tooltip="Change Header Background"
		>
			<IconHeaderBg />
			<span>Change Header Background</span>
		</button>
		<!-- <button on:click={onPaypalOpen} data-title="PayPal" data-tooltip="Add PayPal Smart Button" disabled={!activeBlock}>
			<IconPayPal />
			<span>Add PayPal Smart Button</span>
		</button> -->
		<button
			on:click={onStripeOpen}
			data-title="Stripe"
			data-tooltip="Add Stripe Button"
			disabled={!activeBlock}
		>
			<IconStripe />
			<span>Add Stripe Button</span>
		</button>
		<button
			on:click={onImageSidebarOpen}
			data-title="Image"
			data-tooltip="Add Image"
			disabled={!activeBlock}
		>
			<IconImage />
			<span>Add Image</span>
		</button>
		<button on:click={onBodyOpen} data-title="Background" data-tooltip="Change Background">
			<IconBodyBg />
			<span>Change Body Background</span>
		</button>
		<!-- <button on:click={openSidebarPanel('article')} data-title="Article" data-tooltip="Change Article Background">
			<IconArticleBg />
			<span>Change Article Background</span>
		</button> -->
	
		<SaveButton onClick={onPublishFormOpen} {isUpdate} {isBusy} />
	</section>
</footer>