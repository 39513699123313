<svg id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
        <path stroke-width={0.1} d="M37,43c0,0.6-0.4,1-1,1H12c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h13V2H12c-1.7,0-3,1.3-3,3v38c0,1.7,1.3,3,3,3h24   c1.7,0,3-1.3,3-3V16h-2V43z"/>
        <polygon stroke-width={0.1} points="33,8 33,2 31,2 31,8 25,8 25,10 31,10 31,16 33,16 33,10 39,10 39,8  "/>
        <rect stroke-width={0.1} height="2" width="10" x="17" y="19"/>
        <rect stroke-width={0.1} height="2" width="14" x="17" y="27"/>
        <rect stroke-width={0.1} height="2" width="10" x="17" y="35"/>
    </g>
</svg>