import axios from 'axios';
import ImageKit from "imagekit-javascript";
import { IMAGEKIT_PUBLIC_KEY, IMAGEKIT_ENDPOINT,  } from '../../env-svelte';

/**
 * @summary
 * Format bytes
 *
 * @param bytes number of bytes
 * @returns {string} Formatted file size
 */
export const bytesToSize = (bytes) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes == 0) {
		return '0 Byte'
	};
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/**
 * @summary
 * Handle file change event
 *
 * @description
 * Make sure that a valid image type has been selected
 * 
 * @param {Event} event - the event object
 * @param {Number} maxSize - the max filesize in bytes
 *
 * @returns {void}
 */
export const getSelectedImage = (event, maxSize = 2.5 * 1024 * 1024) => {
	if (!event.target || !event.target.files || !event.target.files.length) {
		return alert('Please select a valid jpg / png / svg image');
	};
	const selectedFile = event.target.files[0];
	if (!selectedFile || ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(selectedFile.type) === false) {
		e.target.value = '';
		return alert('Please select a valid jpg / png / svg image');
	}
	if (!selectedFile || isNaN(selectedFile.size) === true || selectedFile.size > maxSize) {
		e.target.value = '';
		return alert(`Maximum allowed file size is ${bytesToSize(maxSize)}`);
	}
	return selectedFile;
};

/**
 * @summary
 * Get the provided file content as Base64 data string
 *
 * @param {File} file the file object
 */
export const getImageAsBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

/**
 * @summary
 * Upload image to server
 *
 * @returns {Promise}
 */
export const uploadImage = async (file) => new Promise(async (resolve, reject) => {
	let data = null;
	const { size, type, name } = file;
	const alt = name;
	const title = name;
	try {
		data = await getImageAsBase64(file);
		const response = await axios.post('/api/upload', { data, size, type, title, alt, shouldResize: false });
		resolve({ ...response.data, alt, title, original: { size, type, name } });
	} catch (error) {
		alert('Something went wrong trying to upload image, please try again');
		console.log('uploadImage - error', error);
		reject(new Error(error.message));
	}
});

/**
 * @summary
 * Get ImageKit instance
 *
 * @returns {Object}
 */
export const getImageKit = () => new ImageKit({
	publicKey : IMAGEKIT_PUBLIC_KEY,
	urlEndpoint : IMAGEKIT_ENDPOINT,
	authenticationEndpoint : '/api/signature',
});

/**
 * @summary
 * Upload image to ImageKit
 *
 * @param {File} file HTML File element to upload - must have a file
 * @param {String} fileName The image filename
 * @param {String[]} tags Array of image tags
 * @returns {Object}
 */
export const uploadToImageKit = (file, fileName, tags = []) => new Promise((resolve, reject) => {
	const ik = getImageKit();
	ik.upload({ file, fileName, tags }, (err, result) => {
		if (err) {
			console.log('getImageKit error', err);
			return reject(err);
		}
		return resolve(result);
	});
});
