<script>
    import IconNext from "../icons/IconNext.svelte";
    import IconThreeDots from "../icons/IconThreeDots.svelte";

    export let style = {};
    export let onSubmit;
    export let isBusy = false;
</script>

<button on:click|preventDefault={onSubmit} class="multistep-form-submit" type="button" {style}>
    {#if isBusy}
        <IconThreeDots />
    {:else}
        <IconNext />
    {/if}
</button>