<script>
	import { onMount } from 'svelte';

	import SidebarTitle from './SidebarTitle.svelte';
	import Background from '../Background.svelte';
	import { getImageAsBase64 } from '../utils';

	export let headerBg;
	export let onHeaderBgChange;
	export let isSidebarOpen;
	export let onSidebarToogle;

	let form;
	let file;

	/**
	 * Update state on prop change
	 * First time only
	*/
	onMount(() => {
		if (!headerBg) {
			return;
		}
		updateHeaderBg(headerBg);
	});

	/**
	 * Apply current styles to header
	 */
	const updateHeaderBg = async (data) => {
		const eleHeader = document.getElementById('header');
		const {
			isTransparent,
			type,
			code, 
			gradientAngle, 
			gradientOne,
			gradientTwo, 
			gradientCode, 
			pattern,
			position,
			size,
			width,
			height,
			repeat
		} = data;

		eleHeader.style.removeProperty('background');
		eleHeader.style.removeProperty('background-color');
		eleHeader.style.removeProperty('background-image');
		eleHeader.style.removeProperty('background-repeat');
		eleHeader.style.removeProperty('background-size');

		if (isTransparent) {
			return;
		}

		if (type === 'transparent') {
			eleHeader.style.backgroundColor = 'transparent';
		}

		if (type === 'code') {
			eleHeader.style.backgroundColor = type === 'code' ? code : 'transparent';
		}

		// gradient
		if (type === 'gradient') {
			eleHeader.style.backgroundImage = `linear-gradient(${gradientAngle || 0}deg, ${gradientOne} 0%, ${gradientTwo} 100%)`;
		}

		// gradientCode
		if (type === 'gradientCode') {
			eleHeader.style.backgroundImage = gradientCode
				.trim()
				.replace(/^(background:?|background-image:?)/, '')
				.replace(/;$/, '')
				.trim();
		}

		if (type === 'pattern' && (file || pattern)) {
			eleHeader.style.backgroundColor = 'transparent';
			eleHeader.style.backgroundSize = size;
			if (size === 'custom') {
				eleHeader.style.backgroundSize = `${width}rem ${height}rem`;
			}
			eleHeader.style.backgroundRepeat = repeat;
			eleHeader.style.backgroundPosition = position;
			if (file) {
				eleHeader.style.backgroundImage = `url(${await getImageAsBase64(file)})`;
			}
			if (pattern && pattern.upload && !file) {
				eleHeader.style.backgroundImage = `url(${pattern.upload.url})`;
			}
		}
	}

	/**
	 * @summary
	 * Handle file change event
	 * @param newFile {File} the new file
	 */
	const onFileChange = (newFile) => {
		file = newFile;
	}

	/**
	 * Update header bg when selection changes
	 */
	const onCodeChange = (event) => {
		const value = event.detail;
		updateHeaderBg(value);
		onHeaderBgChange(value)
	}
</script>

<form on:submit|preventDefault={onSidebarToogle} bind:this={form}>
	<SidebarTitle>Header background</SidebarTitle>
	<Background on:input={onCodeChange} :bind:file={file} value={headerBg} {onFileChange} />
</form>
