<script>
	import SidebarTitle from './SidebarTitle.svelte';
	export let onSidebarToogle;
	export let onStripeButtonAdd;
	export let onStripeButtonUpdate;
	export let activeStripeId;
	export let activeStripePrivateKey;
	export let activeStripePriceId;
	export let activeStripeIsIdealEnabled;

	let privateKey;
	let priceId;
	let isIdealEnabled = false;

	$: dataChanged(activeStripeId);
	function dataChanged(value) {
		privateKey = activeStripePrivateKey;
		priceId = activeStripePriceId;
		isIdealEnabled = activeStripeIsIdealEnabled
	}

	/**
	 * @summary
	 * Reset form elements
	 *
	 * @returns {void}
	 */
	const reset = () => {
		privateKey = '';
		priceId = '';
		isIdealEnabled = false;
	};

	/**
	 * @summary
	 * Get stripe form data as payload
	 *
	 * @returns {object}
	 */
	const getPayload = () => ({
		id: activeStripeId || null,
		privateKey,
		priceId,
		isIdealEnabled,
	});

	/**
	 * @summary
	 * Handle form submit event
	 *
	 * @param e {Event} The event object
	 * @returns {void}
	 */
	const onSubmit = (e) => {
		e.preventDefault();
		onSidebarToogle();
		const payload = getPayload();
		activeStripeId ? onStripeButtonUpdate(payload) : onStripeButtonAdd(payload);
		reset();
	};
</script>

<form on:submit={onSubmit}>
	<SidebarTitle>Stripe button</SidebarTitle>
	<div>
		<label for="link">Secret key</label>
		<input type="text" name="stripePrivateKey" bind:value={privateKey} />
	</div>
	<div>
		<label for="link">Price ID</label>
		<input type="text" name="stripeProductId" bind:value={priceId} />
	</div>
	<div>
		<label for="link">Accept iDEAL</label>
		<select name="isIdealEnabled" bind:value={isIdealEnabled}>
			<option value="">No</option>
			<option value="1">Yes</option>
		</select>
	</div>
	<div>
		<button type="submit">
			{activeStripeId ? 'Update Stripe Button' : 'Add Stripe Button'}
		</button>
	</div>
</form>
