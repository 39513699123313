<script>
    import IconCheckmarAnimated from "../icons/IconCheckmarAnimated.svelte";

	export let url = '';
	export let onSubmit;
</script>

<div class="card">
	<IconCheckmarAnimated />
	<h3 class="title">Success</h3>
	<p>
		Your site was successfully deployed to
	</p>
	<p>
		<a target="_blank" href={url}>{url}</a>
	</p>
	<p>
		<small>
			Please note that it can take up to 48 hours for DNS changes to propagate across the Internet
		</small>
	</p>
	<button class="button" on:click={onSubmit} type="button">Continue</button>
</div>

<style>
	.card {
		z-index: 60000;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
        display: flex;
		flex-direction: column;
        align-items: center;
        justify-content: center;
		padding: 2rem;
		background-color: #fff;
		font-family: var(--app-font-family);
		text-align: center;
	}

	.title {
		margin-top: 2rem;
		margin-bottom: 1rem;
		font-family: inherit;
		font-size: 2rem;
		font-weight: bold;
	}

	small {
		opacity: 0.5;
		font-size: 0.77em;
	}

	a {
		color: var(--theme-primary);
	}

	.button {
		margin-top: 2rem;
		padding: 1rem 4rem;
		border-radius: var(--border-radius);
		font-family: inherit;
	}
</style>
