<script>
	import { onMount } from 'svelte';
	import { v4 } from 'uuid';
	import TinyMCE from '@tinymce/tinymce-svelte';
	export let setEditor;
	export let setActiveBlockIndex;
	export let editorData = null;
	export let images = [];
	export let smartButtons = [];
	export let stripeButtons = [];
	export let activeBlock;
	export let setActiveBlock;
	export let onContentUpdate;
	export let onStripeButtonEdit;
	export let onImageEdit;
	let eleEditor = document.getElementById('editorjs');
	let editor;
	let offset;

	onMount(() => {
		const updateOffset = () => {
			const bodyRect = document.body.getBoundingClientRect();
			
			// set header position
			offset = bodyRect.y * -1 + 'px';
			document.getElementById('app-footer').style.top = offset;
			
			// set app toolbar position
			// const eleToolbar = document.getElementById('app-toolbar');
			// eleToolbar.style.top = bodyRect.y * -1 + window.innerHeight - eleToolbar.getBoundingClientRect().height + 'px';
		};
		// document.addEventListener('input', updateOffset);
		document.addEventListener('resize', updateOffset);
		document.addEventListener('scroll', updateOffset);
		updateOffset();
	});

	// default editor data
	const defaultData = {
		time: new Date().getTime(),
		blocks: [
			{
				type: 'header',
				data: {
					text: 'Project Proteus',
					level: 1,
				},
			},
			{
				type: 'paragraph',
				data: {
					text: 'Start typing here...',
				},
			},
		],
		version: '1.0',
	};

	/**
	 * @summary
	 * Recreate image block
	 *
	 * @see sidebar/SidebarImage.svelte
	 *
	 * @param block
	 */
	const replaceImageBlock = (block) => {
		const image = images.find((image) => image.id === block.id);
		if (!image) {
			return;
		}
		const { alt, title } = image;
		const { url } = image.upload;
		block.data.text = `
			<img src="${encodeURI(url)}" title="${encodeURI(title)}" alt="${encodeURI(alt)}" />
		`;
	};

	/**
	 * @summary
	 * Recreate smart button block
	 *
	 * @see sidebar/SidebarSmartButton.svelte
	 *
	 * @param block
	 */
	const replaceSmartButtonBlock = (block) => {
		const button = smartButtons.find((btn) => btn.id === block.id);
		if (!button) {
			return;
		}
		const { amount, currency } = button;
		block.data.text = `
			<button type="button" class="paypal" title="${amount} ${currency}">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px" class="svelte-113qyzv"><path fill="#1565C0" d="M18.7,13.767l0.005,0.002C18.809,13.326,19.187,13,19.66,13h13.472c0.017,0,0.034-0.007,0.051-0.006C32.896,8.215,28.887,6,25.35,6H11.878c-0.474,0-0.852,0.335-0.955,0.777l-0.005-0.002L5.029,33.813l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,0.991,1,0.991h8.071L18.7,13.767z"></path><path fill="#039BE5" d="M33.183,12.994c0.053,0.876-0.005,1.829-0.229,2.882c-1.281,5.995-5.912,9.115-11.635,9.115c0,0-3.47,0-4.313,0c-0.521,0-0.767,0.306-0.88,0.54l-1.74,8.049l-0.305,1.429h-0.006l-1.263,5.796l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,1,1,1h7.333l0.013-0.01c0.472-0.007,0.847-0.344,0.945-0.788l0.018-0.015l1.812-8.416c0,0,0.126-0.803,0.97-0.803s4.178,0,4.178,0c5.723,0,10.401-3.106,11.683-9.102C42.18,16.106,37.358,13.019,33.183,12.994z"></path><path fill="#283593" d="M19.66,13c-0.474,0-0.852,0.326-0.955,0.769L18.7,13.767l-2.575,11.765c0.113-0.234,0.359-0.54,0.88-0.54c0.844,0,4.235,0,4.235,0c5.723,0,10.432-3.12,11.713-9.115c0.225-1.053,0.282-2.006,0.229-2.882C33.166,12.993,33.148,13,33.132,13H19.66z"></path></svg>
			</button>
		`;
	};

	/**
	 * @summary
	 * Build all editor blocks
	 */
	const buildBlocks = () => {
		if (!editorData || !editorData.blocks || !editorData.blocks.length) {
			return;
		}
		for (let i = 0; i < editorData.blocks.length; i++) {
			const block = editorData.blocks[i];
			replaceImageBlock(block);
			replaceSmartButtonBlock(block);
		}
	};

	onMount(async () => {
		buildBlocks();
		// initEditor();
		// initMediumEditor();
	});

	/**
	 * @summary
	 * Insert line break in cross browser compatible way on a contenteditable
	 * @see https://stackoverflow.com/questions/35705291/cross-browser-way-to-insert-br-or-p-tag-when-hitting-enter-on-a-contenteditable
	 */
	const insertLineBreak = () => {
		const selection = window.getSelection();
		const range = selection.getRangeAt(0);
		const br = document.createElement('br');
		const zwsp = document.createTextNode('\u200B');
		const textNodeParent = document.getSelection().anchorNode.parentNode;
		const inSpan = textNodeParent.nodeName == 'SPAN';
		const span = document.createElement('span');

		// if the carat is inside a <span>, move it out of the <span> tag
		if (inSpan) {
			range.setStartAfter(textNodeParent);
			range.setEndAfter(textNodeParent);
		}

		// insert the <br>
		range.deleteContents();
		range.insertNode(br);
		range.setStartAfter(br);
		range.setEndAfter(br);

		// create a new span on the next line
		if (inSpan) {
			range.insertNode(span);
			range.setStart(span, 0);
			range.setEnd(span, 0);
		}

		// add a zero-width character
		range.insertNode(zwsp);
		range.setStartBefore(zwsp);
		range.setEndBefore(zwsp);

		// insert the new range
		selection.removeAllRanges();
		selection.addRange(range);
	};

	/**
	 * @summary
	 * Init the Medium editor
	 *
	 * @description
	 * Set default content
	 * When focus changes or content is updated then update active block
	 *
	 * @link https://github.com/yabwe/medium-editor
	 */
	const initMediumEditor = () => {
		editor = new MediumEditor('#editor', {
			extensions: {
				colorPicker: mediumtextcolor.createInstance({
					showHSL: true,
					contentFA: '<i class="fa fa-paint-brush"></i>',
					removeButtonContent: '<i class="fa fa-eraser"></i>',
				}),
			},
			placeholder: {
				text: '',
				text: 'start typing here...',
				hideOnClick: false,
			},
			buttonLabels: 'fontawesome',
			static: true,
			toolbar: {
				diffTop: 5,
				buttons: [
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'anchor',
					'justifyLeft',
					'justifyCenter',
					'justifyRight',
					// 'quote',
					// 'orderedlist',
					// 'unorderedlist',
					// 'indent',
					// 'outdent',
					'textcolor',
					// 'removeFormat'
				],
				//'superscript','subscript', 'justifyFull','pre','html',
			},
		});

		// initial content
		// const defaultData = '<h1></h1><p></>';
		const defaultData = '<p></p>';
		editor.setContent(editorData || defaultData);

		const eleLastParagraph = editor.elements[0].querySelector(
			'p:last-child, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child'
		);
		if (eleLastParagraph) {
			setActiveBlock(eleLastParagraph);
		}

		/**
		 * Update active block whenever content changes
		 *
		 * Replace div tags with p tags
		 * @see https://github.com/yabwe/medium-editor/issues/1459
		 */
		editor.subscribe('editableInput', function (event, editorElement) {
			activeBlock = editor.getSelectedParentElement();
			const blocks = document.querySelectorAll('#editor > div');
			blocks.forEach((block) => {
				const eleP = document.createElement('p');
				eleP.innerHTML = block.innerHTML;
				block.replaceWith(eleP);
			});
		});

		// update active block when editor is focused
		editor.subscribe('focus', function (event, editorElement) {
			activeBlock = editor.getSelectedParentElement();
		});

		// update active block when editor is focused
		editor.subscribe('editableKeypress', (event, editorElement) => {
			if (event.keyCode != 13 || event.shiftKey !== true) {
				return;
			}
			event.preventDefault();
			insertLineBreak();
		});

		// update editor
		setEditor(editor);
	};

	/**
	 * @summary
	 * Replace placehodler text when editor is initialized for the first time
	 *
	 * @description
	 * Don't trigger when editing a subdomain - i.e.
	 */
	const onEditorReady = () => {
		if (editorData) {
			return;
		}
		const elePlaceholder = document.querySelector('.ce-paragraph.cdx-block');
		const removePlaceholder = () => {
			elePlaceholder.innerHTML = '';
			elePlaceholder.removeEventListener('focus', removePlaceholder);
		};
		elePlaceholder.addEventListener('focus', removePlaceholder);
		setActiveBlockIndex(editor.blocks.getBlocksCount());
	};

	/**
	 * @summary
	 * Setup TinyMCE editor
	 * 
	 * @description
	 * This can be used to setup the different event handlers for tiny mce
	 * 
	 * @param tinyEditor the current tiny mce editor reference
	 */
	const setupTinyMce = (tinyEditor) => {
		tinyEditor.on('init', (e) => TinyMceInit(e, tinyEditor));
		tinyEditor.on('change', (e) => onTinyMceChange(e, tinyEditor));
		tinyEditor.on('focusin', (e) => onTinyMceFocusIn(e, tinyEditor));
		tinyEditor.on('focusout', (e) => onTinyMceFocusOut(e, tinyEditor));
	};

	/**
	 * @summary
	 * Triggered when tiny mce editor boots up and is ready to use
	 * 
	 * @description
	 * Used to populate the editor with draft / saved data
	 * Also used to attach element move up / down / edit controls
	 * 
	 * @param e the event object
	 * @param currentEditor the current tiny mce editor reference
	 */
	const TinyMceInit = (e, currentEditor) => {
		console.log('The Editor has initialized.');
		if (editorData) {
			currentEditor.setContent(editorData);
		}
		setEditor(currentEditor);
		addMoveHandlers();
		addEditHandlers();
		addDeleteHandlers();
		currentEditor.ui.registry.addIcon('link', '<svg viewBox="0 0 32 32" width="16" height="16"><path d="M24.78357,17.52661c-0.58545,0.58594-0.58545,1.53613,0.00049,2.12109c0.29297,0.29297,0.67676,0.43945,1.06055,0.43945   s0.76807-0.14648,1.06104-0.43945l1.57422-1.5752c1.94629-1.94629,3.01807-4.53125,3.01807-7.27832   s-1.07178-5.33203-3.01807-7.27832c-4.0127-4.01367-10.54297-4.01367-14.55664,0l-1.57471,1.5752   C11.76306,5.677,11.76306,6.6272,12.349,7.21216c0.58594,0.58594,1.53564,0.58594,2.12158,0l1.57422-1.5752   c2.84326-2.84277,7.46973-2.84277,10.31396,0c1.37939,1.37988,2.13916,3.21094,2.13916,5.15723s-0.75977,3.77734-2.13965,5.15723   L24.78357,17.52661z"/><path d="M3.5116,28.48462c2.00684,2.00684,4.64258,3.00977,7.27832,3.00977s5.27197-1.00391,7.27881-3.00977l1.57471-1.5752   c0.58545-0.58594,0.58545-1.53613-0.00049-2.12109c-0.58594-0.58594-1.53564-0.58594-2.12158,0l-1.57422,1.5752   c-2.84277,2.84277-7.46973,2.84277-10.31445,0c-2.84326-2.84375-2.84326-7.4707,0-10.31445l1.5752-1.5752   c0.58594-0.58594,0.58594-1.53516,0-2.12109s-1.53516-0.58594-2.12109,0l-1.5752,1.5752   C-0.5011,17.94165-0.5011,24.47095,3.5116,28.48462z"/><path d="M22.43298,9.56274c-0.58594-0.58594-1.53613-0.58594-2.12109,0L9.55896,20.31665   c-0.58594,0.58594-0.58594,1.53516,0,2.12109c0.29297,0.29297,0.67676,0.43945,1.06055,0.43945s0.76807-0.14648,1.06055-0.43945   l10.75293-10.75391C23.01892,11.0979,23.01892,10.14868,22.43298,9.56274z"/></svg>');
		currentEditor.ui.registry.addIcon('text-color', '<svg viewBox="0 0 24 24" width="24" height="24"><rect fill="none" height="24" width="24"/><path d="M6.28513 13.091L14.2831 3.60745L15.3286 3.32997L18.1738 5.72949L18.0767 6.80681L10.0788 16.2904L5.89683 17.4003L6.28513 13.091Z" stroke="black" fill="none" /><path fill="none" d="M12.7844 5.15353L16.6778 8.61429" stroke="black" /><path d="M5.5 19H19.5" stroke="black" stroke-linecap="round" fill="none" /></svg>');
		currentEditor.ui.registry.addIcon('highlight-bg-color', '<svg viewBox="0 0 32 32" width="16" height="16"><path d="M16.7070313,2.2929688c-0.390625-0.390625-1.0234375-0.390625-1.4140625,0C14.9135742,2.6723633,6,11.6816406,6,20    c0,5.5141602,4.4858398,10,10,10c5.5136719,0,10-4.4858398,10-10C26,11.6816406,17.0869141,2.6723633,16.7070313,2.2929688z     M16,28c-4.4111328,0-8-3.5888672-8-8c0-6.2075195,5.9248047-13.2612305,8.0004883-15.5410156    C18.0761719,6.7368164,24,13.7836914,24,20C24,24.4111328,20.4111328,28,16,28z"/><path d="M16,24c-2.2055664,0-4-1.8105469-4-4.0356445c0-0.5522461-0.4477539-1-1-1s-1,0.4477539-1,1    C10,23.2924805,12.6914063,26,16,26c0.5522461,0,1-0.4477539,1-1S16.5522461,24,16,24z"/></svg>');
		currentEditor.ui.registry.addIcon('align-left', '<svg viewBox="0 0 24 24" width="16" height="16"><path d="M3,7H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,7Zm0,4H17a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Zm18,2H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-4,4H3a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Z" /></svg>');
		currentEditor.ui.registry.addIcon('align-center', '<svg viewBox="0 0 24 24" width="16" height="16"><path d="M3,7H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,7ZM7,9a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Zm14,4H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-4,4H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Z" /></svg>');
		currentEditor.ui.registry.addIcon('align-right', '<svg viewBox="0 0 24 24" width="16" height="16"><path d="M3,7H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,7ZM21,17H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-8H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0,4H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z" /></svg>');
	};

	/**
	 * @summary
	 * Triggered when tiny mce editor content changes
	 * 
	 * @description
	 * Used to save draft to local storage
	 * 
	 * @param e the event object
	 * @param currentEditor the current tiny mce editor reference
	 */
	const onTinyMceChange = (e, currentEditor) => {
		console.log('The Editor content has changed.');
		onContentUpdate();
	};

	/**
	 * @summary
	 * Triggered when tiny mce editor gains focus
	 * 
	 * @description
	 * Used to set body class for styling elements when editor has focus
	 * 
	 * @param e the event object
	 * @param currentEditor the current tiny mce editor reference
	 */
	const onTinyMceFocusIn = (e, currentEditor) => {
		console.log('onTinyMceFocusIn');
		document.body.classList.add('has-editor-focus');
	};

	/**
	 * @summary
	 * Triggered when tiny mce editor looses focus
	 * 
	 * @description
	 * Used to update body class for styling elements when editor looses focus
	 * 
	 * @param e the event object
	 * @param currentEditor the current tiny mce editor reference
	 */
	const onTinyMceFocusOut = (e, currentEditor) => {
		console.log('onTinyMceFocusOut');
		document.body.classList.remove('has-editor-focus');
		addMoveHandlers();
		addEditHandlers();
		addDeleteHandlers();
	};

	/**
	 * @summary
	 * This creates a button element for use with move up / down controls
	 */
	const getHanlderButton = () => {
		const id = v4();
		const button = document.createElement('button');
		button.setAttribute('type', 'button');
		button.setAttribute('id', id);
		button.setAttribute('data-handler', id);
		button.setAttribute('contenteditable', false);
		return button;
	};

	/**
	 * @summary
	 * This crates styles for the provided shadow root
	 * 
	 * @description
	 * This is needed because shadow root elements can't be styled directly
	 * This is used for styling move up / down / edit buttons
	 * 
	 * @param host the element which has a shadow inside it
	 */
	const addShadowStyles = (host) => {
		const style = document.createElement('style');
		style.innerHTML = `
			button {
				opacity: 0.33;
				padding: 5px;
				background: transparent;
				border: 0;
				border-radius: 3px;
				cursor: pointer;
				outline: 0;
			}
			button:hover {
				opacity: 1;
			}
			button path {
				fill: #789;
			}
			button:hover svg,
			button:hover svg path,
			button:hover svg circle,
			button:hover svg rect {
				fill: #0099ff;
			}
		`;
		host.shadowRoot.appendChild(style);
	}

	/**
	 * @summary
	 * Add a move up button as a shadow dom inside the eleRoot
	 * 
	 * @description
	 * This is used to add a button which can be used to move the parent element to top
	 * 
	 * @param parent the parent element - h1 / h2 / h3 / h4 / h5 / h6 / p
	 * @param eleRoot the root element to host the shadow dom - this is inside the parent element
	 */
	const addMoveUpButton = (parent, eleRoot) => {
		const button = getHanlderButton();
		button.innerHTML = `
			<svg viewBox="0 0 512 512" width="20" height="20" style="display: block;">
				<path stroke-width="1" d="M341.8,50.9c113.3,47.7,166.5,178.3,118.7,291.6S282.2,508.9,168.9,461.2S2.5,282.9,50.3,169.6  C85,87.1,165.9,33.4,255.4,33.4C285.1,33.4,314.5,39.3,341.8,50.9z M63.4,255.4c0,106,86,192,192,192s192-86,192-192  s-86-192-192-192S63.4,149.4,63.4,255.4z M370.7,227.4L255.4,112.1L140.1,227.4l21.2,21.2l79.1-79.2v208.2h30V169.4l79.1,79.1  L370.7,227.4z"/>
			</svg>
		`;
		button.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			if (parent && parent.parentElement && parent.previousSibling) {
				parent.parentElement.insertBefore(parent, parent.previousSibling);
			}
			return false;
		});
		const shadow = eleRoot.attachShadow({ mode: 'open' });
		shadow.appendChild(button);
		addShadowStyles(eleRoot);
	};

	/**
	 * @summary
	 * Add a move down button as a shadow dom inside the eleRoot
	 * 
	 * @description
	 * This is used to add a button which can be used to move the parent element to bottom
	 * 
	 * @param parent the parent element - h1 / h2 / h3 / h4 / h5 / h6 / p
	 * @param eleRoot the root element to host the shadow dom - this is inside the parent element
	 */
	const addMoveDownButton = (parent, eleRoot) => {
		const button = getHanlderButton();
		button.innerHTML = `
			<svg viewBox="0 0 512 512" width="20" height="20" style="display: block;">
				<path stroke-width="1" d="M341.8,461.2c113.3-47.7,166.5-178.3,118.7-291.6S282.2,3.2,168.9,50.9S2.5,229.2,50.3,342.5  C85,425,165.9,478.7,255.4,478.7C285.1,478.7,314.5,472.8,341.8,461.2z M63.4,256.7c0-106,86-192,192-192s192,86,192,192  s-86,192-192,192S63.4,362.7,63.4,256.7z M370.7,284.7L255.4,400L140.1,284.7l21.2-21.2l79.1,79.2V134.5h30v208.2l79.1-79.1  L370.7,284.7z"/>
			</svg>
		`;
		button.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			if (parent && parent.parentElement && parent.nextSibling) {
				parent.parentElement.insertBefore(parent.nextSibling, parent);
			}
			return false;
		});
		const shadow = eleRoot.attachShadow({ mode: 'open' });
		shadow.appendChild(button);
		addShadowStyles(eleRoot);
	};

	/**
	 * @summary
	 * Add a move down button as a shadow dom inside the eleRoot
	 * 
	 * @description
	 * This is used to add a button which can be used to move the parent element to bottom
	 * 
	 * @param parent the parent element - h1 / h2 / h3 / h4 / h5 / h6 / p
	 * @param eleRoot the root element to host the shadow dom - this is inside the parent element
	 */
	const addEditButton = (parent, eleRoot) => {
		const button = getHanlderButton();
		button.innerHTML = `
			<svg viewBox="0 0 32 32" width="20" height="20" style="display: block;">
				<path d="M29.12,6.05,26,2.88a3,3,0,0,0-4.24,0L6.29,18.29a3.06,3.06,0,0,0-.72,1.18L2.08,29.92l10.46-3.49a3.15,3.15,0,0,0,1.17-.72L29.12,10.29a3,3,0,0,0,0-4.24Zm-21,13.28,8.75-8.74,1.58,1.58L9.67,20.92ZM18.24,9.17l1.59-1.58,4.58,4.58-1.58,1.59ZM7.1,21.19l3.72,3.71L5.25,26.75Zm5.57,2.73-1.59-1.59,8.75-8.74,1.58,1.58Zm15-15-1.88,1.88L21.24,6.17l1.88-1.88A1,1,0,0,1,23.83,4a1,1,0,0,1,.71.29l3.17,3.18a1,1,0,0,1,.29.7A1,1,0,0,1,27.71,8.88Z"/>
			</svg>
		`;
		button.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			const id = parent.id;
			if (parent && id && parent.querySelector('[data-stripe]') !== undefined) {
				onStripeButtonEdit(id);
			}
			if (parent && id && parent.querySelector('img') !== undefined) {
				onImageEdit(id);
			}
			return false;
		});
		const shadow = eleRoot.attachShadow({ mode: 'open' });
		shadow.appendChild(button);
		addShadowStyles(eleRoot);
	};

	/**
	 * @summary
	 * Add a delete button as a shadow dom inside the eleRoot
	 * 
	 * @description
	 * This is used to add a button which can be used to move the parent element to top
	 * 
	 * @param parent the parent element - h1 / h2 / h3 / h4 / h5 / h6 / p
	 * @param eleRoot the root element to host the shadow dom - this is inside the parent element
	 */
	const addDeleteButton = (parent, eleRoot) => {
		const button = getHanlderButton();
		button.innerHTML = `
			<svg viewBox="0 0 32 32" width="20" height="20" style="display: block;">
				<g data-name="Layer 17" id="Layer_17">
					<path class="cls-1" d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28A3,3,0,0,1,24,31Z"/>
					<path class="cls-1" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"/>
					<path class="cls-1" d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"/>
					<path class="cls-1" d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z"/>
					<path class="cls-1" d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z"/>
					<path class="cls-1" d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z"/>
				</g>
			</svg>
		`;
		button.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			parent.remove();
			return false;
		});
		const shadow = eleRoot.attachShadow({ mode: 'open' });
		shadow.appendChild(button);
		addShadowStyles(eleRoot);
	};

	/**
	 * @summary
	 * Add move up / down buttons to all root level elements
	 * 
	 * @description
	 * Add move up / down buttons to all root level - h1 / h2 / h3 / h4 / h5 / h6 / p elements inside the editor
	 */
	const addMoveHandlers = () => {
		const rootElements = document.querySelectorAll(
			'#tiny-editor > h1, #tiny-editor > h2, #tiny-editor > h3, #tiny-editor > h4, #tiny-editor > h5, #tiny-editor > h6, #tiny-editor > p'
		);
		rootElements.forEach((ele) => {
			if (ele.innerText.trim() === '') {
				return;
			}
			let upHandlerId = ele.querySelector('[data-up-handler-id]');
			if (!upHandlerId) {
				const eleUpHandler = document.createElement('span');
				eleUpHandler.setAttribute('contenteditable', false);
				eleUpHandler.setAttribute('data-up-handler-id', v4());
				ele.appendChild(eleUpHandler);
				addMoveUpButton(ele, eleUpHandler);
			}

			let downHandlerId = ele.querySelector('[data-down-handler-id]');
			if (!downHandlerId) {
				const eleDownHandler = document.createElement('span');
				eleDownHandler.setAttribute('contenteditable', false);
				eleDownHandler.setAttribute('data-down-handler-id', v4());
				ele.appendChild(eleDownHandler);
				addMoveDownButton(ele, eleDownHandler);
			}
		});
	};

	/**
	 * @summary
	 * Add edit buttons to all root level image and stripe elements
	 */
	const addEditHandlers = () => {
		const rootElements = document.querySelectorAll(
			'#tiny-editor > p'
		);
		rootElements.forEach((ele) => {
			if (ele.querySelector('data-stripe') == undefined && ele.querySelector('img') == undefined) {
				return;
			}
			let handlerId = ele.querySelector('[data-edit-handler-id]');
			if (handlerId) {
				return;
			}
			const eleHandler = document.createElement('span');
			eleHandler.setAttribute('contenteditable', false);
			eleHandler.setAttribute('data-edit-handler-id', v4());
			ele.appendChild(eleHandler);
			addEditButton(ele, eleHandler);
		});
	};

	/**
	 * @summary
	 * Add edit buttons to all root level image and stripe elements
	 */
	const addDeleteHandlers = () => {
		const rootElements = document.querySelectorAll(
			'#tiny-editor > p'
		);
		rootElements.forEach((ele) => {
			if (ele.innerText.trim() === '' && ele.querySelector('data-stripe') == undefined && ele.querySelector('img') == undefined) {
				return;
			}
			let handlerId = ele.querySelector('[data-delete-handler-id]');
			if (handlerId) {
				return;
			}
			const eleHandler = document.createElement('span');
			eleHandler.setAttribute('contenteditable', false);
			eleHandler.setAttribute('data-delete-handler-id', v4());
			ele.appendChild(eleHandler);
			addDeleteButton(ele, eleHandler);
		});
	};

	const initEditor = () => {
		editor = new EditorJS({
			tools: {
				header: {
					class: Header,
					config: {
						placeholder: 'Enter a header',
						levels: [1, 2, 3],
						defaultLevel: 2,
					},
				},
				paragraph: {
					class: Paragraph,
					inlineToolbar: true,
				},
				breakLine: {
					class: BreakLine,
					inlineToolbar: true,
					shortcut: 'CMD+SHIFT+ENTER',
				},
				Color: {
					class: ColorPlugin,
					config: {
						colorCollections: [
							'#000',
							'#FF1300',
							'#EC7878',
							'#9C27B0',
							'#673AB7',
							'#3F51B5',
							'#0070FF',
							'#03A9F4',
							'#00BCD4',
							'#4CAF50',
							'#8BC34A',
							'#CDDC39',
							'#FFF',
						],
						defaultColor: '#FF1300',
						type: 'text',
					},
				},
				Marker: {
					class: ColorPlugin,
					config: {
						defaultColor: '#FFBF00',
						type: 'marker',
						colorCollections: [
							'#000',
							'#FF1300',
							'#EC7878',
							'#9C27B0',
							'#673AB7',
							'#3F51B5',
							'#0070FF',
							'#03A9F4',
							'#00BCD4',
							'#4CAF50',
							'#8BC34A',
							'#CDDC39',
							'#FFF',
						],
					},
				},
			},
			data: editorData ? editorData : defaultData,
			onReady: onEditorReady,
			sanitize: () => ({
				text: {
					br: true,
					div: true,
				},
			}),
		});

		eleEditor.addEventListener('focusin', onTinyMceFocusIn);

		eleEditor.addEventListener('focusout', onTinyMceFocusOut);

		setEditor(editor);
	};
</script>

<navbar id="toolbar" />

<TinyMCE
	apiKey="ro4cwsmmhcdxgy0ew6zupdtwwsiqstrukndve9w30vk8gh9a"
	conf={{
		selector: '#tiny-editor',
		// skin: 'snow',
		// icons: 'thin',
		menubar: false,
		tools: '',
		toolbar:
			// 'bold italic underline link | h1 h2 h3 h4 h5 h6 | alignleft aligncenter alignright | forecolor backcolor removeformat | fontselect',
			'h1 h2 h3 h4 h5 h6 | alignleft aligncenter alignright link forecolor fontselect',
		fixed_toolbar_container: '#tiny-toolbar',
		// toolbar_mode: 'sliding',
		toolbar_mode: 'floating',
		toolbar_persist: true,
		quickbars_selection_toolbar:
			'bold italic underline link h1 h2 h3 h4 h5 h6 forecolor backcolor removeformat',
		font_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace',
		quickbars_insert_toolbar: '',
		plugins: 'autoresize link',
		default_link_target: '_blank',
		inline: true,
		branding: false,
		placeholder: 'start typing here...',
		link_context_toolbar: true,
		setup: setupTinyMce,
		forced_root_block: 'p',
		// force_br_newlines : true,
		// force_p_newlines: true,
	}}
/>

<style>
</style>
