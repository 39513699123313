<script>
	import SidebarTitle from "./SidebarTitle.svelte";
	export let smartButtons = [];
	export let onSidebarToogle;
	export let onSmartButtonAdd;
	let code = '';
	let link = '';
	let email = '';
	let amount = '';
	let currency = 'USD';
	let description = 'Checkout';

	/**
	 * @summary
	 * Reset form elements
	 *
	 * @returns {void}
	 */
	const reset = () => {
		code = '';
		link = '';
		email = '';
		amount = '';
		currency = '';
		description = '';
	};

	/**
	 * @summary
	 * Extract payment data array from PayPal smart button raw html code
	 *
	 * @returns {string}
	 */
	const getDataString = () => {
		const startIndex = code.indexOf('[');
		const endIndex = code.indexOf(']');
		if (startIndex === -1 || endIndex === -1) {
			return;
		}
		return code.substr(startIndex, endIndex - startIndex + 1);
	};

	/**
	 * @summary
	 * Get JSON data of paypal payment information extracted from smart button
	 *
	 * @returns {object}
	 */
	const getDataJson = () => {
		try {
			const json = JSON.parse(getDataString());
			if (!json || !json.length || !json[0].amount) {
				return;
			}
			return json[0];
		} catch (err) {
			console.log('getDataJson Error', err);
			return;
		}
	};

	/**
	 * @summary
	 * Handle code change event
	 *
	 * @returns {void}
	 */
	const onCodeChange = () => {
		const json = getDataJson();
		if (!json) {
			amount = '';
			currency = '';
			description = '';
			return;
		}
		amount = json.amount.value || 0;
		currency = json.amount.currency_code || '';
		description = json.description || '';
	};

	/**
	 * @summary
	 * Handle property change event
	 *
	 * @returns {void}
	 */
	const onPropertyChange = () => {
		// const json = getDataJson();
		// if (!json) {
		// 	return;
		// }
		// const dataString = getDataString();
		// json.amount.value = amount;
		// json.amount.currency_code = currency;
		// json.description = description;
		// code = code.replace(dataString, JSON.stringify([json]));
	};

	/**
	 * @summary
	 * Insert new PayPal smart button into editorjs and save data in global var
	 *
	 * @returns {void}
	 */
	const insertButton = async () => {
		const data = { code, email, amount, currency, description };
		window.editor.blocks.insert(
			'paragraph',
			{
				text: `
				<button type="button" class="paypal" title="${amount} ${currency}">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px" class="svelte-113qyzv"><path fill="#1565C0" d="M18.7,13.767l0.005,0.002C18.809,13.326,19.187,13,19.66,13h13.472c0.017,0,0.034-0.007,0.051-0.006C32.896,8.215,28.887,6,25.35,6H11.878c-0.474,0-0.852,0.335-0.955,0.777l-0.005-0.002L5.029,33.813l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,0.991,1,0.991h8.071L18.7,13.767z"></path><path fill="#039BE5" d="M33.183,12.994c0.053,0.876-0.005,1.829-0.229,2.882c-1.281,5.995-5.912,9.115-11.635,9.115c0,0-3.47,0-4.313,0c-0.521,0-0.767,0.306-0.88,0.54l-1.74,8.049l-0.305,1.429h-0.006l-1.263,5.796l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,1,1,1h7.333l0.013-0.01c0.472-0.007,0.847-0.344,0.945-0.788l0.018-0.015l1.812-8.416c0,0,0.126-0.803,0.97-0.803s4.178,0,4.178,0c5.723,0,10.401-3.106,11.683-9.102C42.18,16.106,37.358,13.019,33.183,12.994z"></path><path fill="#283593" d="M19.66,13c-0.474,0-0.852,0.326-0.955,0.769L18.7,13.767l-2.575,11.765c0.113-0.234,0.359-0.54,0.88-0.54c0.844,0,4.235,0,4.235,0c5.723,0,10.432-3.12,11.713-9.115c0.225-1.053,0.282-2.006,0.229-2.882C33.166,12.993,33.148,13,33.132,13H19.66z"></path></svg>
				</button>`,
			},
			{},
			window.activeBlockIndex
		);
		data.id = window.editor.blocks.getBlockByIndex(window.activeBlockIndex).id;
		window.smartButtons.push(data);
	};

	/**
	 * @summary
	 * Handle form submit event
	 *
	 * @param e {Event} The event object
	 * @returns {void}
	 */
	const onSubmit = (e) => {
		e.preventDefault();
		onSidebarToogle();
		onSmartButtonAdd({ code, email, amount, currency, description, link });
		reset();
	};
</script>

<form on:submit={onSubmit}>
	<SidebarTitle>Smart button</SidebarTitle>
	<!-- <div>
		<label for="amount">PayPal smart button code</label>
		<textarea bind:value={code} on:input={onCodeChange} />
	</div> -->
	<!-- <div>
		<label for="email">Email</label>
		<input type="email" required name="email" bind:value={email} on:input={onPropertyChange} />
	</div>
	<div>
		<label for="amount">Amount</label>
		<input type="number" required name="amount" bind:value={amount} on:input={onPropertyChange} />
	</div>
	<div>
		<label for="currency">Currency</label>
		<input type="text" required bind:value={currency} on:input={onPropertyChange} />
	</div>
	<div>
		<label for="description">Description</label>
		<input type="text" required bind:value={description} on:input={onPropertyChange} />
	</div> -->
	<div>
		<label for="link">PayPal smart button link</label>
		<textarea name="link" bind:value={link} />
	</div>
	<div>
		<!-- <button type="submit" disabled={!email || !amount || !currency || !description}>Add Smart Button</button> -->
		<button type="submit" disabled={link.trim().length === 0}>Add Smart Button</button>
	</div>
</form>
