<script>
	import { getContext } from 'svelte';
	import { TABS } from './Tabs.svelte';

	const tab = {};
	const { registerTab, selectTab, selectedTab } = getContext(TABS);

	registerTab(tab);
</script>

<style>
	button {
		margin: 0;
		background: none;
		border: none;
		border-bottom: 2px solid transparent;
		border-radius: 0;
		color: #888;
		font-size: 0.5em;
		font-weight: bold;
		text-transform: uppercase;
	}

	button:focus,
	button:active,
	button:active:focus {
		background-color: transparent;
	}
	
	.selected {
		background-color: transparent;
		border-bottom: 2px solid #0099ff;
		color: #333;
	}
</style>

<button type="button" class:selected="{$selectedTab === tab}" on:click="{() => selectTab(tab)}">
	<slot></slot>
</button>
