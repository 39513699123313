<script>
	import SidebarSmartButton from './SidebarSmartButton.svelte';
	import SidebarImage from './SidebarImage.svelte';
	import SidebarLogo from './SidebarLogo.svelte';
	import SidebarHeaderBg from './SidebarHeaderBg.svelte';
	import SidebarBodyBg from './SidebarBodyBg.svelte';
	import SidebarPublish from './SidebarPublish.svelte';
	import SidebarStripe from './SidebarStripe.svelte';
	import SidebarArticleBg from './SidebarArticleBg.svelte';

	// root subdomain
	export let subdomain;
	export let title;
	export let description;

	// busy and update status
	export let isBusy;
	export let isUpdate;

	// on publish
	export let onPublish;

	// sidebar
	export let activeSidebarId;
	export let onSidebarToogle;
	export let isSidebarOpen;
	export let isSidebarLocked;
	export let onSidebarLock;
	export let onSidebarUnlock;

	// logo
	export let logo;
	export let onLogoChange;
	export let onLogoInsert;

	// body bg
	export let bodyBg;
	export let onBodyBgChange;

	// article bg
	export let articleStyles;
	export let onArticleStyleChange;

	// header bg
	export let headerBg;
	export let onHeaderBgChange;

	// images
	export let images;
	export let onImageAdd;
	export let onImageLivePreview;
	export let activeimageId;
	export let activeimageTitle;
	export let activeimageAlt;

	// smart buttons
	export let smartButtons;
	export let onSmartButtonAdd;

	// stripe buttons
	export let activeStripeId;
	export let activeStripePrivateKey;
	export let activeStripePriceId;
	export let activeStripeIsIdealEnabled;
	export let onStripeButtonAdd;
	export let onStripeButtonUpdate;

	// autosave
	export let onDisableAutosave;
	export let onEnableAutosave;

	// image hot update - without changing the image
    export let onImageAttributeUpdate;
</script>

<aside class={isSidebarOpen ? 'is-active' : ''}>
	<section class={activeSidebarId === 'paypal' ? '' : 'hidden'}>
		<SidebarSmartButton {onSidebarToogle} {smartButtons} {onSmartButtonAdd} />
	</section>
	<section class={activeSidebarId === 'stripe' ? '' : 'hidden'}>
		<SidebarStripe
			{smartButtons}
			{activeStripeId}
			{activeStripePrivateKey}
			{activeStripePriceId}
			{activeStripeIsIdealEnabled}
			{onSidebarToogle}
			{onStripeButtonAdd}
			{onStripeButtonUpdate}
		/>
	</section>
	<section class={activeSidebarId === 'image' ? '' : 'hidden'}>
		<SidebarImage
			{activeSidebarId}
			{onSidebarToogle}
			{activeimageId}
			{activeimageTitle}
			{activeimageAlt}
			{onImageAdd}
			{onImageLivePreview}
			{onSidebarLock}
			{onSidebarUnlock}
			{onDisableAutosave}
			{onImageAttributeUpdate}
			{onEnableAutosave}
			{isSidebarOpen}
			isImageBar={true}
			hasClearButton={true}
		/>
	</section>
	<section class={activeSidebarId === 'logo' ? '' : 'hidden'}>
		<SidebarLogo {onSidebarToogle} {logo} {onLogoChange} {onLogoInsert} {isSidebarOpen} />
	</section>
	<section class={activeSidebarId === 'header' ? '' : 'hidden'}>
		<SidebarHeaderBg {onSidebarToogle} {headerBg} {onHeaderBgChange} {isSidebarOpen} />
	</section>
	<section class={activeSidebarId === 'body' ? '' : 'hidden'}>
		<SidebarBodyBg {onSidebarToogle} {bodyBg} {onBodyBgChange} {isSidebarOpen} />
	</section>
	<section class={activeSidebarId === 'article' ? '' : 'hidden'}>
		<SidebarArticleBg {onSidebarToogle} {articleStyles} {onArticleStyleChange} />
	</section>
	<section class={activeSidebarId === 'publish' ? '' : 'hidden'}>
		<SidebarPublish
			{isBusy}
			{onPublish}
			{isUpdate}
			bind:subdomain
			bind:title
			bind:description
		/>
	</section>
</aside>
<div class="overlay {isSidebarOpen ? 'is-active' : ''}" on:click={onSidebarToogle} />

<style>
	aside,
	.overlay {
		z-index: 100;
		opacity: 0;
		visibility: hidden;
		position: fixed;
		top: 0;
		bottom: 0;
		transition: all 0.35s;
	}
	aside {
		z-index: 3000;
		overflow-y: auto;
		right: 0;
		width: 12.5rem;
		padding: 1.5rem;
		padding-bottom: 5rem;
		background-color: #fff;
		border-left: 1px solid var(--border-color);
		box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
		/* box-shadow: 0 40px 85px rgba(6, 144, 185, 0.2); */
		font-family: var(--app-font-family);
		font-size: 0.7rem;
		transform: translateX(110%);
	}
	.overlay {
		right: 15em;
		left: 0;
		/* margin-bottom: 3.5em; */
	}
	.is-active {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}
	.overlay.is-active {
		transition-delay: 0.15s;
	}
</style>
