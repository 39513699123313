<script>
	import { onMount, afterUpdate } from 'svelte';
	import { Tabs, TabList, TabPanel, Tab } from '../tabs';
	import ColorPickerSimple from '../ColorPickerSimple.svelte';
	import ColorPicker from '../ColorPicker.svelte';
	import SidebarTitle from './SidebarTitle.svelte';
	import BoxShadow from '../BoxShadow.svelte';
	import Background from '../Background.svelte';
	import { getImageAsBase64 } from '../utils';
	import Button from '../Button.svelte';

	export let bodyBg;
	export let onBodyBgChange;
	export let onSidebarToogle;
	export let isSidebarOpen;

	let background = {};
	let textBoxShadow = {};
	let text = {
		radius: 0,
		background: '',
	};

	let form = null;
	let file = null;
	let isBusy = false;
	let shouldResize = false;

	onMount(() => {
		syncValues();
	});

	afterUpdate(() => {
		syncValues();
	});

	const syncValues = () => {
		if (!bodyBg) {
			return;
		}
		if (bodyBg.background) {
			background = bodyBg.background;
		}
		if (bodyBg.text) {
			text = bodyBg.text || '';
			text.radius = text.radius || 0;
		}
		if (bodyBg.textBoxShadow) {
			textBoxShadow = bodyBg.textBoxShadow;
		}
		updateStyles(bodyBg);
	}

	const clearStyles = (ele) => {
		ele.style.removeProperty('background');
		ele.style.removeProperty('background-color');
		ele.style.removeProperty('background-image');
		ele.style.removeProperty('background-repeat');
		ele.style.removeProperty('background-size');
	}

	const updateBodyStyles = async (data) => {
		if (!data || typeof data !== 'object') {
			return;
		}

		const eleBody = document.body;
		const {
			isTransparent,
			type,
			code,
			gradientAngle,
			gradientOne,
			gradientTwo,
			gradientCode,
			pattern,
			position,
			size,
			width,
			height,
			repeat
		} = data;

		if (isTransparent) {
			clearStyles(eleBody);
			return;
		}

		if (type === 'transparent') {
			clearStyles(eleBody);
			eleBody.style.backgroundColor = 'transparent';
		}

		if (type === 'code') {
			clearStyles(eleBody);
			eleBody.style.backgroundColor = type === 'code' ? code : 'transparent';
		}

		// gradient
		if (type === 'gradient') {
			clearStyles(eleBody);
			eleBody.style.backgroundImage = `linear-gradient(${gradientAngle || 0}deg, ${gradientOne} 0%, ${gradientTwo} 100%)`;
		}

		// gradientCode
		if (type === 'gradientCode' && gradientCode) {
			clearStyles(eleBody);
			eleBody.style.backgroundImage = gradientCode
				.trim()
				.replace(/^(background:?|background-image:?)/, '')
				.replace(/;$/, '')
				.trim();
		}

		if (type === 'pattern' && (file || pattern)) {
			eleBody.style.backgroundColor = 'transparent';
			eleBody.style.backgroundSize = size;
			if (size === 'custom') {
				eleBody.style.backgroundSize = `${width}rem ${height}rem`;
			}
			eleBody.style.backgroundRepeat = repeat;
			eleBody.style.backgroundPosition = position;
			if (file) {
				eleBody.style.backgroundImage = `url(${await getImageAsBase64(file)})`;
			} else if (pattern && pattern.upload && !file) {
				eleBody.style.backgroundImage = `url(${pattern.upload.url})`;
			}
		}
	}

	const updateTextStyles = async (data) => {
		if (!data || typeof data !== 'object') {
			return;
		}
		const { radius, background } = data;
		const eleEditor = document.getElementById('editor');
		eleEditor.style.borderRadius = `${radius}px`;
		eleEditor.style.backgroundColor = background;
	}

	const updateTextBoxShadowStyles = async (data) => {
		if (!data || typeof data !== 'object') {
			return;
		}
		const { shadowX, shadowY, shadowBlur, shadowSpread, shadowColor } = data;
		const eleEditor = document.getElementById('editor');
		if (shadowColor) {
			eleEditor.style.boxShadow = `${shadowX}px ${shadowY}px ${shadowBlur}px ${shadowSpread}px ${shadowColor}`;
		} else {
			eleEditor.style.removeProperty('box-shadow');
		}
	}

	/**
	 * Apply current styles to body
	 */
	const updateStyles = async (data) => {
		if (!data || typeof data !== 'object') {
			return;
		}
		updateBodyStyles(data.background);
		updateTextStyles(data.text);
		updateTextBoxShadowStyles(data.textBoxShadow);
	}

	const onBodyBackgroundChange = (event) => {
		const value = event.detail;
		if (!value) {
			return;
		}
		const newBodyBg = { ...bodyBg, ...{ background: value } };
		onBodyBgChange(newBodyBg);
		updateStyles(newBodyBg);
	}

	const onBodyTextChange = () => {
		const newBodyBg = { ...bodyBg, ...{ text } };
		onBodyBgChange(newBodyBg);
		updateStyles(newBodyBg);
	}

	const onBodyTextBoxShadowChange = (event) => {
		const value = event.detail;
		if (!value) {
			return;
		}
		const newBodyBg = { ...bodyBg, ...{ textBoxShadow: value } };
		onBodyBgChange(newBodyBg);
		updateStyles(newBodyBg);
	}

	/**
	 * @summary
	 * Handle file change event
	 * @param newFile {File} the new file
	 */
	const onFileChange = (newFile) => {
		file = newFile;
	}

	/**
	 * Save current styles to global var and close sidebar
	 */
	const onSubmit = async () => {
		// isBusy = true;
		// onBodyBgChange({
		// 	type,
		// 	code,
		// 	gradientAngle,
		// 	gradientOne,
		// 	gradientTwo,
		// 	gradientCode,
		// 	pattern,
		// 	position,
		// 	size,
		// 	repeat,
		// });
		// isBusy = false;
		// isDirty = false;
		// onSidebarToogle();
	};

	/**
	 * Handle body text clear event
	 */
	const onBodyTextClear = () => {
		text.background = null;
		text.radius = 0;
		onBodyTextChange();
	}
</script>

<form on:submit|preventDefault={onSubmit} bind:this={form}>
	<!-- <SidebarTitle>Body background</SidebarTitle> -->
	<Tabs>
		<TabList>
			<Tab>Background</Tab>
			<Tab>Text</Tab>
			<Tab>Shadow</Tab>
		</TabList>

		<TabPanel>
			<SidebarTitle>Body background</SidebarTitle>
			<Background on:input={onBodyBackgroundChange} {onFileChange} value={background} />
		</TabPanel>

		<TabPanel>
			<SidebarTitle>Body Text</SidebarTitle>
			<div>
				<label for="cornerRadius">Corner Radius: {text.radius || 0}px</label>
				<input type="range" min={0} step={1} max={100} name="cornerRadius" bind:value={text.radius} on:input={onBodyTextChange} />
			</div>
			<div>
				<!-- <ColorPickerSimple bind:value={text.background} on:input={onBodyTextChange} label="Text Background" /> -->
				<ColorPicker bind:value={text.background} on:input={onBodyTextChange} label="Text Background" />
			</div>
			<div class="footer">
				<Button type="button" label="Clear" onClick={onBodyTextClear} />
			</div>
		</TabPanel>

		<TabPanel>
			<SidebarTitle>Body Box Shadow</SidebarTitle>
			<BoxShadow value={textBoxShadow} on:input={onBodyTextBoxShadowChange} />
		</TabPanel>
	</Tabs>
</form>
