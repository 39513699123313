<script>
    import Button from '../Button.svelte';
    import SidebarTitle from './SidebarTitle.svelte';
    export let onPublish;
    export let isBusy;
    export let isUpdate;
	export let subdomain = '';
	export let title = '';
	export let description = '';
</script>

<form on:submit|preventDefault={onPublish}>
    <SidebarTitle>{isUpdate ? 'Save' : 'Publish'}</SidebarTitle>
    <div>
        <label for="subdomain">Subdomain</label>
        <input type="text" bind:value={subdomain} disabled={isBusy} required />
    </div>
    <div>
        <label for="title">Title</label>
        <input type="text" name="title" bind:value={title} disabled={isBusy} required />
    </div>
	<div>
		<label for="description">Meta Description</label>
		<textarea bind:value={description} disabled={isBusy}  />
	</div>
    <div>
        <Button label={isUpdate ? 'Save' : 'Publish'} isDisabled={isBusy} {isBusy} />
    </div>
</form>
