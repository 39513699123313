<script>
	import colorParse from 'color-parse';
	import colorSpace from 'color-space';
	import { onMount, afterUpdate } from 'svelte';
	import { createEventDispatcher } from 'svelte'
	export let label = 'Code';
	export let value = '';
	let h = 0;
	let s = 0;
	let l = 0;
	let a = 1;

	const dispatch = createEventDispatcher()

	onMount(() => {
		if (value) {
			onCodeChange();
		}
		// onChage();
	})

	// afterUpdate(() => {
	// 	onCodeChange();
	// });

	$: dataChanged(value);
	function dataChanged(value) {
		onCodeChange();
	}

	const onChage = () => {
		value = `hsla(${h}, ${s}%, ${l}%, ${a})`;
		dispatch('input', value);
	}

	const onCodeChange = () => {
		try {
			const color = colorParse(value);
			const alpha = isNaN(color.alpha) ? 1 : color.alpha;
			const hsl = colorSpace[color.space]['hsl'](color.values);
			h = hsl[0];
			s = hsl[1];
			l = hsl[2];
			a = alpha;
			// dispatch('input', value);
		} catch (err) {
			console.log('onCodeChange - error while converting color space', err);
		}
	}
</script>

<form style={`--h: ${h}; --l: ${l}%; --s: ${s}%; --a: ${a};`}>
	<label>
		{label}
		<input type="text" name="code" bind:value={value} on:input={onCodeChange} />
	</label>

	<label>
		Hue: {h}
		<input type="range" bind:value={h} min="0" max="360" step="0.1" name="h" on:input={onChage} />
	</label>

	<label>
		Saturation: {s}%
		<input type="range" bind:value={s} min="0" max="100" step="0.1" name="s" on:input={onChage} />
	</label>

	<label>
		Lightness: {l}%
		<input type="range" bind:value={l} min="0" max="100" step="0.1" name="l" on:input={onChage} />
	</label>

	<label>
		Alpha: {a}
		<input type="range" bind:value={a} min="0" max="1" step="0.01" name="a" on:input={onChage} />
	</label>
</form>

<style>
	form {
		--h: 180;
		--s: 50%;
		--l: 50%;
		--a: 1;
		--border-color: hsl(var(--h), 100%, 35%);
		--border-radius: 8px;
		--box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
	}

	label {
		display: flex;
		flex-direction: column;
		width: 100%;
		border-radius: var(--border-radius);
	}

	label + label {
		margin-top: 1.5em;
	}

	input[type='range'] {
		-webkit-appearance: none;
		width: 100%;
		height: 0.1em;
		margin: 0;
		margin-top: 0.75em;
		padding: 0;
		border: none;
		border-radius: var(--border-radius);
		box-shadow: var(--box-shadow);
	}

	input[type='range']:focus {
		outline: none;
		box-shadow: var(--box-shadow);
	}

	input[type="range"] {
		background-image: var(--background-image);
	}

	input[type="range"]::-webkit-slider-runnable-track {
		background-image: var(--background-image);
	}

	input[type="range"]:focus::-webkit-slider-runnable-track {
		background-image: var(--background-image);
	}

	input[type="range"]::-moz-range-track {
		background-image: var(--background-image);
	}

	input[type="range"]::-ms-track {
		background-image: var(--background-image);
	}

	input[type="range"]::-ms-fill-lower {
		background-image: var(--background-image);
	}

	input[type="range"]::-ms-fill-upper {
		background-image: var(--background-image);
	}

	input[type="range"]:focus::-ms-fill-lower {
		background-image: var(--background-image);
	}

	input[type="range"]:focus::-ms-fill-upper {
		background-image: var(--background-image);
	}

	[name="h"] {
		--s: 100%;
		--l: 50%;
	}

	input[name="h"] {
		/* credit to Jamie Kudla: https://codepen.io/JKudla/pen/GpYXxZ */
		--background-image: linear-gradient(
			to right,
			hsl(0, 100%, 50%) 0%,
			hsl(30, 100%, 50%) 8.3%,
			hsl(60, 100%, 50%) 16.6%,
			hsl(90, 100%, 50%) 25%,
			hsl(120, 100%, 50%) 33.3%,
			hsl(150, 100%, 50%) 41.6%,
			hsl(180, 100%, 50%) 50%,
			hsl(210, 100%, 50%) 58.3%,
			hsl(240, 100%, 50%) 66.6%,
			hsl(270, 100%, 50%) 75%,
			hsl(300, 100%, 50%) 83.3%,
			hsl(330, 100%, 50%) 91.6%,
			hsl(360, 100%, 50%) 100%
		);
	}

	[name="s"] {
		--background-image: linear-gradient(
			to right,
			hsl(var(--h), 0%, var(--l)) 0%,
			hsl(var(--h), 100%, var(--l)) 100%
		);
	}

	[name="l"] {
		--background-image: linear-gradient(
			to right,
			hsl(var(--h), var(--s), 0%) 0%,
			hsl(var(--h), var(--s), 50%) 50%,
			hsl(var(--h), var(--s), 100%) 100%
		);
	}

	[name="a"] {
		--background-image: linear-gradient(
			to right,
			hsla(var(--h), var(--s), var(--l), 0%) 00%,
			hsla(var(--h), var(--s), var(--l), 100%) 100%
		);
	}

	[type='range']::-moz-range-thumb {

		height: 1.2em;
		width: 1.2em;
		background-color: hsl(var(--h), var(--s), var(--l));
		/* border: 2px solid #fff; */
		border: none;
		border-radius: 50%;
		box-shadow: var(--box-shadow);
	}
	[type='range']::-webkit-slider-thumb {

		height: 1.2em;
		width: 1.2em;
		background-color: hsl(var(--h), var(--s), var(--l));
		/* border: 2px solid #fff; */
		border: none;
		border-radius: 50%;
		box-shadow: var(--box-shadow);
	}
	[type='range']::-ms-thumb {
		height: 1.2em;
		width: 1.2em;
		background-color: hsl(var(--h), var(--s), var(--l));
		/* border: 2px solid #fff; */
		border: none;
		border-radius: 50%;
		box-shadow: var(--box-shadow);
	}

	:glboal(.transparent-bg) {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
	}
</style>
