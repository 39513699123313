<script>
	import { afterUpdate } from 'svelte';
	import { Tabs, TabList, TabPanel, Tab } from '../tabs';
	import Button from '../Button.svelte';
	import SidebarImage from './SidebarImage.svelte';
	import SidebarTitle from './SidebarTitle.svelte';
	
	export let isSidebarOpen;
	export let onSidebarToogle;
	export let onLogoChange;
	export let onLogoInsert;
	export let logo

	let logoStart;
	let logoCenter;
	let logoEnd;
	let type = '';
	let maxSize = 5 * 1024 * 1024;

	afterUpdate(() => {
		if (!logo) {
			return;
		}
		syncValues();
	});

	const syncValues = () => {
		const { start, center, end } = logo;

		// set start logo
		if (start && start.upload && start.upload.url) {
			logoStart = start;
			onInsert(start, 'logo-start');
		}

		// set center logo
		if (center && center.upload && center.upload.url) {
			logoCenter = center;
			onInsert(center, 'logo-center');
		}

		// set end logo
		if (end && end.upload && end.upload.url) {
			logoEnd = end;
			onInsert(end, 'logo-end');
		}
	}

	/**
	 * @summary
	 * Clear header logo and set logo to blank object
	 *
	 * @param {string} id - the ID of the logo element
	 */
	const onClear = (id) => {
		onLogoChange({ start: logoStart, center: logoCenter, end: logoEnd });
		const eleLogo = document.getElementById(id);
		if (!eleLogo) {
			return;
		}
		eleLogo.innerHTML = '';
	}

	const onClearStartLogo = () => {
		logoStart = {};
		onClear('logo-start');
	}

	const onClearCenterLogo = () => {
		logoCenter = {};
		onClear('logo-center');
	}

	const onClearEndLogo = () => {
		logoEnd = {};
		onClear('logo-end');
	}

	/**
	 * @summary
	 * Update header logo
	 *
	 * @param data {object} - alt, title, upload
	 * @param selector {string} - the target css selector
	 */
	const onInsert = (data, selector) => {
		const { width, height, url } = data.upload;
		const { alt, title } = data;

		// create new responsive logo div
		const eleDiv = document.createElement('div');
		eleDiv.style.setProperty('--aspect-ratio', `${width} / ${height}`);

		// get existing logo
		const eleLogo = document.getElementById(selector);
		eleLogo.innerHTML = '';

		// creaate new logo image
		const eleImg = document.createElement('img');
		eleImg.setAttribute('src', url);
		eleImg.setAttribute('alt', alt);
		eleImg.setAttribute('title', title);

		// place new logo
		eleDiv.appendChild(eleImg);
		eleLogo.appendChild(eleImg);

		// update logo in app
		onLogoChange({ start: logoStart, center: logoCenter, end: logoEnd });
	}

	const onInsertLogoStart = (data) => {
		onLogoInsert(data);
		logoStart = data;
		onInsert(data, 'logo-start');
	}

	const onInsertLogoCenter = (data) => {
		onLogoInsert(data);
		logoCenter = data;
		onInsert(data, 'logo-center');
	}

	const onInsertLogoEnd = (data) => {
		onLogoInsert(data);
		logoEnd = data;
		onInsert(data, 'logo-end');
	}

</script>

<form>

    <Tabs>

        <TabList>
            <Tab>Left</Tab>
            <Tab>Center</Tab>
            <Tab>Right</Tab>
        </TabList>

		<!-- left logo -->
        <TabPanel>
			<SidebarImage
				onInsert={onInsertLogoStart}
				{onSidebarToogle}
				image={logoStart}
				label="Save"
				heading="Left Logo"
				isResetDisabled={true}
				hasClearButton
				clearLabel="Clear Logo"
				maxSize={maxSize}
				shouldResize={false}
				onClear={onClearStartLogo}
			/>
		</TabPanel>

		<!-- center logo -->
        <TabPanel>
			<SidebarImage
				onInsert={onInsertLogoCenter}
				{onSidebarToogle}
				image={logoCenter}
				label="Save"
				heading="Center Logo"
				isResetDisabled={true}
				hasClearButton
				clearLabel="Clear Logo"
				maxSize={maxSize}
				shouldResize={false}
				onClear={onClearCenterLogo}
			/>
		</TabPanel>
        
		<!-- right logo -->
		<TabPanel>
			<SidebarImage
				onInsert={onInsertLogoEnd}
				{onSidebarToogle}
				image={logoEnd}
				label="Save"
				heading="Right Logo"
				isResetDisabled={true}
				hasClearButton
				clearLabel="Clear Logo"
				maxSize={maxSize}
				shouldResize={false}
				onClear={onClearEndLogo}
			/>
        </TabPanel>

    </Tabs>

</form>
