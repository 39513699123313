<script>
    export let isDisabled = false;
    export let isBusy = false;
    export let label;
    export let classNames = null;
    export let id = null;
    export let type = 'submit';
    export let onClick = () => null;
</script>

<button {id} class={classNames} {type} on:click={onClick} disabled={isDisabled || isBusy}>
    {#if !isBusy}
        {label}
    {/if}
    {#if isBusy}
        <i></i>
    {/if}
</button>

<style>
    button {
        background-color: transparent;
        border: 1px solid;
        color: #000;
        font-family: inherit;
        font-size: 0.6rem;
        transition: box-shadow 0.25s;
    }

    button:hover,
    button:focus,
    button:active,
    button:active:focus {
        background-color: transparent;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    i {
        display: block;
        width: 1em;
        height: 1em;
        margin: 0 auto;
        border: 2px solid #000;
        border-radius: 99em;
        border-top-color: transparent;
        animation: spinAround 500ms infinite linear;
    }

    @-webkit-keyframes spinAround {
        from {
            transform: rotate(0)
        }

        to {
            transform: rotate(359deg)
        }
    }

    @keyframes spinAround {
        from {
            transform: rotate(0)
        }

        to {
            transform: rotate(359deg)
        }
    }
</style>