<script>
    import axios from 'axios';
    import { v4 } from 'uuid';
    import { afterUpdate } from 'svelte';
    import Button from '../Button.svelte';
    import { bytesToSize, uploadToImageKit } from '../utils';
    import SidebarTitle from './SidebarTitle.svelte';
    export let activeSidebarId = null;
    export let isSidebarOpen = null;
	export let isImageBar = null;
	export let onSidebarToogle = null;
	export let onDisableAutosave = null;
	export let onEnableAutosave = null;
	export let onImageAdd = null;
	export let onImageLivePreview = null;
	export let onInsert = null;
	export let image = null;
	export let heading = null;
	export let label = null;
    export let onClear = null;
	export let hasClearButton = null;
	export let clearLabel = null;
	export let isResetDisabled = false;
	export let maxSize = 5 * 1024 * 1024;
	export let shouldResize = true;

    // sidebar lock status
    export let isSidebarLocked = null;
    export let onSidebarLock = () => {};
    export let onSidebarUnlock = () => {};

    // active image
    export let activeimageId = null;
    export let activeimageTitle = null;
    export let activeimageAlt = null;

    // image hot update - without changing the image
    export let onImageAttributeUpdate = null;

	$: dataChanged(activeimageId);
	function dataChanged(value) {
        id = value;
		alt = activeimageAlt;
		title = activeimageTitle;
	}

    import { getNotificationsContext } from 'svelte-notifications';
	const { addNotification } = getNotificationsContext();

    let id = null;
    let file = '';
	let title = '';
	let alt = '';
    let upload = {};
    let isBusy = false;
    let form = null;
    let shouldUpdate = true;

    // this is reactive variable
    // we can edit the form if a file was selected or when editing an existing image
    $: canEdit = file || activeimageId;

    afterUpdate(() => {
        if (!image || !shouldUpdate) {
            return;
        }
        alt = image.alt;
        title = image.title;
        upload = image.upload;
        shouldUpdate = false;
    });

	/**
	 * @summary
	 * Reset form elements
	 *
	 * @returns {void}
	 */
	const reset = () => {
        form.reset();
        // file = null;
        upload = {};
        isBusy = false;
        id = null;
        title = '';
        alt = '';
	};

    /**
     * @summary
     * Validate image form and make sure all fields are valid
     *
     * @description
     * Image format must be jpg / png / svg
     * File size must be smaller than 2mb
     * Title and alt must be present
     */
    const isFormValid = () => {
        const titleNormalized = title.trim();
        const altNormalized = alt.trim();

        // check file
        if (!file || ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(file.type) === false) {
            addNotification({
                text: 'Please select a valid jpg / png / svg image',
                position: 'top-right',
                type: 'danger',
                removeAfter: 5000,
            });
            return false;
        }

        // check title and alt
        if (!titleNormalized || !altNormalized) {
            addNotification({
                text: 'Please add image title and alt text',
                position: 'top-right',
                type: 'danger',
                removeAfter: 5000,
            });
            return false;
        }

        if (!file || isNaN(file.size) === true || file.size > maxSize) {
            addNotification({
                text: `Maximum allowed file size is ${bytesToSize(maxSize)}`,
                position: 'top-right',
                type: 'danger',
                removeAfter: 5000,
            });
            return false;
        }

        return true;
    }

	/**
	 * @summary
	 * Handle file change event
     *
     * @description
     * Make sure that a valid image type has been selected
     * 
     * @description
     * if you click the add photo icon but dont do anything (changed your mind) clicking outside the side panel closes it.
     * This is the default behaviour for all the other icons
	 *
	 * @returns {void}
	 */
	const onImageChange = (e) => {
        if (activeSidebarId === 'image') {
            onSidebarLock();
            onDisableAutosave();
        }
        file = null;
        if (!e.target || !e.target.files || !e.target.files.length) {
            return;
        };
        const selectedFile = e.target.files[0];
        if (!selectedFile || ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(selectedFile.type) === false) {
            return alert('Please select a valid jpg / png / svg image');
        }
        file = selectedFile;
        console.log(file);
        updateLivePreview();
	};

    /**
     * @summary
     * Update live preview - send the image as base64, title and alt
     *
     * @description
     * Use the id to change the image in the DOM
     * The image is saved only when the sidepanel is closed
     * This keeps everything lightweight
     *
     * @returns {void}
     */
    const updateLivePreview = async () => {
        if (typeof onImageLivePreview !== 'function') {
            return
        }
        if (!id) {
            id = v4();
        }
        const base64 = await getImageAsBase64();
        onImageLivePreview({ id, base64, title, alt });
    }

    /**
     * @summary
     * Get the provided file content as Base64 data string
     *
     * @param file
     */
    const getImageAsBase64 = () =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

	/**
	 * @summary
	 * Insert new image into editorjs and save data in global images var
	 *
	 * @returns {void}
	 */
	const insertImage = (url) => {
		window.editor.blocks.insert(
			'paragraph',
			{
				text: `
                    <img src="${encodeURI(url)}" title="${encodeURI(title)}" alt="${encodeURI(alt)}" />
				`,
			},
			{},
			window.activeBlockIndex
		);
		upload.id = window.editor.blocks.getBlockByIndex(window.activeBlockIndex).id;
		window.images.push(upload);
	};

    /**
     * @summary
     * Upload image to server
     * 
     * @param base64 {string} The file image as base 64
     * @param title {string}
     * @param alt {string}
     * @param name {string}
     * @param type {string}
     * @param size {number}
     * @param shouldResize {boolean}
     * @param shouldSkipCloud {boolean}
     * 
     * @returns {Promise}
     */
    const uploadImage = async (
        imgBase64,
        imgTitle,
        imgAlt,
        imgName,
        imgType,
        imgSize,
        shouldResize,
        shouldSkipCloud,
    ) => new Promise(async (resolve, reject) => {
        try {
            // // get base64 of image
            // const uploadResponse = await axios.post('/api/upload', {
            //     data: imgBase64,
            //     size: imgSize,
            //     type: imgType,
            //     title: imgTitle,
            //     alt: imgAlt,
            //     shouldResize,
            //     shouldSkipCloud,
            // });

            // get data from response
            // const uploadData = uploadResponse.data;

            // try and upload to cloud
            // const cloudUploadResponse = await axios.post('/api/upload-to-cloud', {
            //     data: uploadData.data,
            //     title: uploadData.title,
            //     alt: uploadData.alt,
            // });
            const ikResponse = await uploadToImageKit(file, imgName);

            // prepare response
            upload = {
                upload: ikResponse,
                width: ikResponse.width,
                height: ikResponse.height,
                url: ikResponse.url,
                alt: imgAlt,
                title: imgTitle,
                original: {
                    size: imgSize,
                    type: imgType,
                    name: imgName,
                },
                metadata: {},
            };

            // return response
            resolve(upload);
        } catch (error) {
            console.log('uploadImage error', error);
            reject(new Error(error.message));
        }
    });

    /**
     * @summary
     * Remove the active image block and reset id
     *
     * @returns {void}
     */
    const removeActiveImage = () => {
        if (!id) {
            return;
        }
        const ele = document.getElementById(id);
        id = undefined;
        if (!ele) {
            return;
        }
        ele.remove();
    }

    /**
     * @summary
     * Handle clear event
     */
    const handleClear = async () => {
        removeActiveImage();
        onSidebarUnlock();
        onEnableAutosave();
        onSidebarToogle();
        reset();
        if (onClear && typeof onClear === 'function') {
            await onClear();
        }
    }
    
    /**
     * @summary
     * Cancel image edit
     */
    const onCancel = () => {
        onSidebarUnlock();
        onSidebarToogle();
        reset();
    }

    /**
     * @summary
     * Get image as base64 and do upload
     *
     * @description
     * Retry uploads 3 times - on failure notify user
     *
     * @returns {void}
     */
    const doUpload = async (imgId, imgTitle, imgAlt) => {
        const { name, type, size } = file;
        const base64 = await getImageAsBase64(file);

        // response of upload
        let uploadResponse;

        // no of times to retry
        let retries = 3;

        // track status of upload
        let isDone = false;

        // upload with retires
        while (retries >= 0 && isDone != true) {
            try {
                uploadResponse = await uploadImage(base64, imgTitle, imgAlt, name, type, size, shouldResize, true);
                isDone = true;
            } catch {
                retries -= 1;
            }
        }
        
        // reset the image - be careful - something might go wrong
        // Reset to null only for image sidebar
        // on background - this can cause issue and remove the entire background!
        if (isImageBar === true) {
            file = null;
        }

        // something went wrong trying to upload the image
        if (!isDone) {
            addNotification({
                text: 'Something went wrong trying to upload image. Please remove the image and try again.',
                position: 'top-right',
                type: 'danger',
                removeAfter: 5000,
            });
            return
        }

        // insert image - via hook or normally
        if (typeof onInsert === 'function') {
            onInsert({ ...uploadResponse, id: imgId });
        } else {
            onImageAdd({ ...uploadResponse, id: imgId });
        }
    }

	/**
	 * @summary
	 * Handle form submit event
	 *
	 * @returns {void}
	 */
	const onSubmit = async () => {
        if (activeimageId && !file) {
            onImageAttributeUpdate({ activeimageId, id, alt, title });
            onSidebarUnlock();
            onSidebarToogle();
            return;
        }

        // validate form
        const isValid = await isFormValid();
        if (isValid !== true) {
            return;
        }

        isBusy = true;

        // close sidebar and continue in background
        // onSidebarUnlock();
        // onSidebarToogle();

        // upload image to server
        await doUpload(id, title, alt);

        // reset if not manually disabled
        if (isResetDisabled !== true) {
            reset();
        }

        // close sidebar and continue in background
        onSidebarUnlock();
        onSidebarToogle();

        // release busy status
        isBusy = false;
	};
</script>

<form on:submit|preventDefault={onSubmit} bind:this={form}>
    <SidebarTitle>{heading ? heading : 'Image'}</SidebarTitle>
    <div>
        <label for="logo">Select image</label>
        <input type="file" on:input={onImageChange} disabled={isBusy} />
    </div>
    <div>
        <label for="title">Title</label>
        <input type="text" name="title" bind:value={title} on:change={updateLivePreview} disabled={!canEdit || isBusy} />
    </div>
    <div>
        <label for="alt">Alt</label>
        <input type="text" name="alt" bind:value={alt} on:change={updateLivePreview} disabled={!canEdit || isBusy} />
    </div>
    <div>
        <Button label={label ? label : 'Save'} isDisabled={!canEdit} {isBusy} />
    </div>
    {#if hasClearButton}
        <div>
            <Button type="button" label={clearLabel || 'Clear'} isDisabled={isBusy} onClick={handleClear} />
        </div>
    {/if}
    {#if activeimageId && !file}
        <div>
            <Button label="Cancel" onClick={onCancel} />
        </div>
    {/if}
    <slot name="footer-end" />
</form>
