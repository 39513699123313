<script>
	import { onMount, afterUpdate, createEventDispatcher } from 'svelte';
	import ColorPickerSimple from './ColorPickerSimple.svelte';
	import ColorPicker from './ColorPicker.svelte';
	import { getSelectedImage, uploadImage } from './utils';
	import { Tabs, TabList, TabPanel, Tab } from './tabs';
	import Switch from './Switch.svelte';
	import Button from './Button.svelte';

	const dispatch = createEventDispatcher();

	export let onFileChange;
	export let onClear;
	export let value;
	export let clearLabel = 'Clear';

	let file;
	let isBusy = false;
	let type = '';
	let code = '#fff';
	let gradientAngle = '';
	let gradientOne = '#fff';
	let gradientTwo = '#fff';
	let gradientCode = '';
	let pattern = null;
	let position = '';
	let size = '';
	let width = 0;
	let height = 0;
	let repeat = '';
	let shouldUpdate = true;
	let isTransparent = false;

	const isSafari = navigator.userAgent.indexOf('Safari') === -1 ? false : true;

	const backgroundSizeOptions = [
		'auto',
		'cover',
		'contain',
		'custom',
	];

	const backgroundRepeatOptions = [
		/* Keyword values */
		'repeat',
		'repeat-x',
		'repeat-y',
		'space',
		'round',
		'no-repeat',

		/* Two-value syntax: horizontal | vertical */
		'repeat space',
		'repeat repeat',
		'round space',
		'no-repeat round',
	];

	const backgroundPositionOptions = [
		'left top',
		'left center',
		'left bottom',
		'right top',
		'right center',
		'right bottom',
		'center top',
		'center center',
		'center bottom',
	];

	afterUpdate(() => {
		syncValues();
	});

	/**
	 * @summary
	 * Sync props
	 *
	 * @description
	 * For Safari - we should reset shouldUpdate
	 * For other browsers we shouldn't
	 * This is a bug related to how prop updates are triggered
	 */
	const syncValues = () => {
		if (!value || !value.type || !shouldUpdate) {
			return;
		}
		type = value.type;
		isTransparent = isTransparent === undefined ? true : isTransparent;
		code = value.code;
		gradientAngle = value.gradientAngle || 0;
		gradientOne = value.gradientOne;
		gradientTwo = value.gradientTwo;
		gradientCode = value.gradientCode;
		pattern = value.pattern;
		position = value.position;
		size = value.size;
		width = value.width || 0;
		height = value.height || 0;
		repeat = value.repeat;
		if (isSafari && shouldUpdate) {
			shouldUpdate = false;
		}
	}

	/**
	 * @summary
	 * Update value via 2 way dynamic data binding
	 * Emit update event
	 *
	 * @description
	 * First enable prop sync
	 * Generate a change issue
	 * Then disable prop sync
	 * This is needed as prop update event triggers before this change event
	 *
	 * For Safari - we should reset shouldUpdate
	 * For other browsers we shouldn't
	 * This is a bug related to how prop updates are triggered
	 *
	 * @returns {void}
	 */
	const onChange = () => {
		shouldUpdate = true;
		const data = {
			isTransparent,
			type,
			code,
			gradientAngle,
			gradientOne,
			gradientTwo,
			gradientCode,
			pattern,
			position,
			size,
			width,
			height,
			repeat,
		};
		dispatch('input', data);
		if (!isSafari) {
			shouldUpdate = false;
		}
	}

	/**
	 * @summary
	 * Handle file change event
     *
     * @description
     * Make sure that a valid image type has been selected
	 *
	 * @returns {void}
	 */
	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		onFileChange(file);
		onChange();
		try {
			pattern = await uploadImage(file);
			// clear file - this will empty the file in the parent element
			// without this images will get embedded as base 64 which will hog page size
			onFileChange();
			onChange();
		} catch (err) {
			return;
		}
	}

	/**
	 * @summary
	 * Handle clear event
	 */
	const handleClear = () => {
		type = '';
		if (onClear && typeof onClear === 'function') {
			onClear();
		}
		onChange();
	}
</script>

<!-- <Switch bind:checked={isTransparent} label="Transparent" on:input={onChange} /> -->

{#if isTransparent === false}
	<div>
		<label for="type">Background Type</label>
		<!-- svelte-ignore a11y-no-onchange -->
		<select name="type" bind:value={type} on:change={onChange}>
			<option value=""></option>
			<option value="code">Color</option>
			<option value="gradient">Gradient</option>
			<option value="gradientCode">Gradient Code</option>
			<option value="pattern">Pattern / Image</option>
		</select>
	</div>
{/if}

{#if type === 'code' && isTransparent === false}
	<!-- <ColorPickerSimple bind:value={code} on:input={onChange} /> -->
	<ColorPicker bind:value={code} on:input={onChange} />
{/if}

{#if type === 'gradient' && isTransparent === false}
	<div>
		<label for="angle">Gradient Angle: {gradientAngle}deg</label>
		<!-- <input type="text" name="angle" bind:value={gradientAngle} disabled={type !== 'gradient'} on:input={onChange} /> -->
		<input type="range" min={0} step={1} max={360} name="angle" bind:value={gradientAngle} on:input={onChange} />
	</div>

	<Tabs>
		<TabList>
			<Tab>Color 1</Tab>
			<Tab>Color 2</Tab>
		</TabList>
		<TabPanel>
			<ColorPicker bind:value={gradientOne} on:input={onChange} />
		</TabPanel>
		<TabPanel>
			<ColorPicker bind:value={gradientTwo} on:input={onChange} />
		</TabPanel>
	</Tabs>
	<!-- <ColorPickerSimple bind:value={gradientOne} on:input={onChange} label="Gradient Color 1" /> -->
	<!-- <ColorPickerSimple bind:value={gradientTwo} on:input={onChange} label="Gradient Color 2" /> -->
{/if}

{#if type === 'gradientCode' && isTransparent === false}
	<div>
		<label for="code">Gradient Code</label>
		<textarea
			name="code"
			placeholder="Insert gradient code here"
			bind:value={gradientCode}
			disabled={type !== 'gradientCode'}
			on:input={onChange}
		/>
	</div>
{/if}

{#if type === 'pattern' && isTransparent === false}
	<div>
		<label for="logo">Select Pattern</label>
		<input type="file" on:input={onImageChange} disabled={type !== 'pattern' || isBusy} />
	</div>
	<div>
		<!-- svelte-ignore a11y-no-onchange -->
		<select name="size" bind:value={size} on:change={onChange}>
			{#each backgroundSizeOptions as option}
				<option value={option}>{option}</option>
			{/each}
		</select>
	</div>
	{#if size === 'custom'}
		<div>
			<label for="width">Width: {width}rem</label>
			<input type="range" min={0} step={1} max={99} name="width" bind:value={width} on:input={onChange} />
		</div>
		<div>
			<label for="height">Height: {height}rem</label>
			<input type="range" min={0} step={1} max={99} name="height" bind:value={height} on:input={onChange} />
		</div>	
	{/if}
	<div>
		<label for="position">Background Position</label>
		<input type="text" name="position" bind:value={position} disabled={type !== 'pattern'} on:input={onChange} />
	</div>
	<div>
		<label for="repeat">Background Repeat</label>
		<!-- svelte-ignore a11y-no-onchange -->
		<select name="repeat" bind:value={repeat} on:change={onChange} disabled={type !== 'pattern'}>
			<option value="">auto</option>
			{#each backgroundRepeatOptions as option}
				<option value={option}>{option}</option>
			{/each}
		</select>
	</div>
{/if}

<div class="footer">
	<Button type="button" label={clearLabel} isDisabled={isBusy} onClick={handleClear} />
</div>	
