<script>
	import { Confirm } from 'svelte-confirm';
	import IconNew from './icons/IconNew.svelte';
	export let onNew;
</script>

<Confirm confirmTitle="Yes" cancelTitle="No" let:confirm={confirmThis}>
	<button on:click={() => confirmThis(onNew)} data-title="Logo" data-tooltip="New Page">
		<IconNew />
		<span>New Page</span>
	</button>
	<h4 slot="title" class="title is-4">Create new page?</h4>
	<span slot="description"
		>This will clear the current page without saving. You won't be able to revert this!</span
	>
</Confirm>
